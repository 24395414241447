import React, { useEffect, useState } from 'react'

import { CheckCircleIcon, ArrowPathIcon } from '@heroicons/react/24/outline'

type AutoSaveIndicatorProps = {
  isSaving: boolean
}

const AutoSaveIndicator = ({
  isSaving,
}: AutoSaveIndicatorProps): JSX.Element | null => {
  const [showSaved, setShowSaved] = useState(false)
  const [fadeClass, setFadeClass] = useState('')
  const [hasSavedOnce, setHasSavedOnce] = useState(false) // Track if save has happened at least once

  useEffect(() => {
    // If saving is complete and saving has occurred at least once, show "Saved!" message
    if (!isSaving && hasSavedOnce) {
      setShowSaved(true)
      setFadeClass('')

      const fadeOutTimeout = setTimeout(() => {
        setFadeClass('opacity-0 transition-opacity duration-1000') // Fades out over 1 second
      }, 4000) // Starts fading after 4 seconds

      const hideTimeout = setTimeout(() => {
        setShowSaved(false)
      }, 5000) // Fully removes after 5 seconds

      return () => {
        clearTimeout(fadeOutTimeout)
        clearTimeout(hideTimeout)
      }
    }

    if (isSaving) {
      setShowSaved(false) // Hide saved message when saving starts
      setHasSavedOnce(true) // Mark that a save has occurred
    }
  }, [isSaving, hasSavedOnce])

  if (!isSaving && !showSaved) return null // Show nothing if not saving and nothing to show

  return (
    <div className="flex items-center justify-center px-2">
      {isSaving ? (
        <div className="flex items-center">
          <ArrowPathIcon className="mr-1 h-5 w-5 animate-spin text-gray-500" />
          <span className="text-sm font-medium text-gray-500">Saving...</span>
        </div>
      ) : (
        showSaved && (
          <div className={`flex items-center ${fadeClass}`}>
            <CheckCircleIcon className="mr-1 h-5 w-5 text-green-500" />
            <span
              className="text-sm font-medium text-green-500"
              data-testid="auto-save-indicator"
            >
              Saved!
            </span>
          </div>
        )
      )}
    </div>
  )
}

export default AutoSaveIndicator
