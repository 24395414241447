import React, { FC } from 'react'

import Modal from 'src/components/Modal'
import ReactMapTemplatesCell from 'src/components/ReactMap/ReactMapTemplatesCell'
import { CreateMapsFromTemplateMutation } from 'src/components/ReactMap/ReactMapTemplatesCell/ReactMapTemplatesCell'
import { UseBooleanReturnType } from 'src/lib/hooks/UseBoolean'

export interface ReactMapTemplatesModalProps {
  modalVisible: UseBooleanReturnType
  createMapsFromTemplate: CreateMapsFromTemplateMutation
  selectedCategory: { id: number }
}

const ReactMapTemplatesModal: FC<ReactMapTemplatesModalProps> = ({
  modalVisible,
  createMapsFromTemplate,
  selectedCategory,
}) => {
  return (
    <Modal
      title={'Process Map Templates'}
      dialogClassName={'!p-0 max-w-[520px]'}
      open={modalVisible.value}
      onClose={() => {
        modalVisible.setFalse()
      }}
    >
      <ReactMapTemplatesCell
        createMapsFromTemplate={createMapsFromTemplate}
        selectedCategory={selectedCategory}
        modalVisible={modalVisible}
      />
    </Modal>
  )
}

export default ReactMapTemplatesModal
