import React, { type FC } from 'react'

import { KnowledgeBaseItemType } from 'types/graphql'

import type { CreateArgs } from 'src/components/KnowledgeBase/KnowledgeBase'
import { KB_ITEM_TYPE } from 'src/components/KnowledgeCell'
import type { KBItemSorted } from 'src/components/KnowledgeCell/types'

import Button from '../Library/Button'

import KBFolder from './KBFolder'
import KBFolderListItem from './KBFolderListItem'
import KBFolderTitleActionBar from './KBFolderTitleActionBar'

interface KBFolderListProps {
  categories: KBItemSorted[]
  selectedKbItem: KBItemSorted
  handleSetSelectedKbItem: (item: KBItemSorted) => void
  handleSideBarEdit: (id: number) => void
  onArchiveClick: (kbItem: KBItemSorted) => void
  onCreateClick: (args: CreateArgs) => void
  userCanEdit: boolean
  isLoading: { id: number | null; status: boolean }
  isTablet: boolean
}

const KBFolderList: FC<KBFolderListProps> = ({
  categories,
  selectedKbItem,
  handleSetSelectedKbItem,
  handleSideBarEdit,
  onArchiveClick,
  onCreateClick,
  userCanEdit,
  isLoading,
  isTablet,
}) => {
  const maxCategoryLevels = 2 // Maximum levels of categories to render

  const renderCategories = (categories: KBItemSorted[], currentLevel = 1) => {
    if (currentLevel > maxCategoryLevels) return null // Stop rendering if the level exceeds the maximum

    return categories.map((folder) => (
      <div
        key={folder.id}
        className={currentLevel === 1 ? 'mb-4 last:mb-0' : ''}
      >
        <KBFolder
          folder={folder}
          isSubFolder={currentLevel > 1}
          handleSetSelectedKbItem={handleSetSelectedKbItem}
          FolderTitleActions={
            !userCanEdit ? null : (
              <KBFolderTitleActionBar
                id={folder.id}
                onClick={handleSideBarEdit}
                onArchive={() => onArchiveClick(folder)}
                isDraft={folder.status === 'DRAFT'}
                userCanEdit={userCanEdit}
                isTablet={isTablet}
                isGlobal={folder?.isGlobal}
              />
            )
          }
        >
          <div className="bg-white">
            {/* Render subcategories only if they are within the maxCategoryLevels */}
            {folder.children && currentLevel < maxCategoryLevels && (
              <>
                {renderCategories(
                  folder.children.filter((item) => item.type === 'CATEGORY'),
                  currentLevel + 1,
                )}
              </>
            )}

            {/* Render items that are not categories */}
            {folder.children &&
              folder.children
                .filter((item: KBItemSorted) => item.type !== 'CATEGORY')
                .map((item: KBItemSorted, index: number) => (
                  <div key={index}>
                    <KBFolderListItem
                      selectedKbItem={selectedKbItem}
                      handleSetSelectedKbItem={handleSetSelectedKbItem}
                      folder={item}
                      userCanEdit={userCanEdit}
                    />
                  </div>
                ))}

            {/* Show "Category is Empty" if cat empty or at level 2 and has no items (DOCUMENT or other types) */}
            {(currentLevel === 2 || folder.children.length === 0) &&
              (!folder.children.some(
                (child: KBItemSorted) =>
                  child.type !== ('CATEGORY' as KnowledgeBaseItemType),
              ) ||
                folder.children.every(
                  (child) =>
                    child.type === 'CATEGORY' &&
                    (!child.children || child.children.length === 0),
                )) && (
                <div className={`grow ${userCanEdit ? 'pt-3' : 'py-3'} px-4`}>
                  <p className="italic text-gray-500 text-sm">
                    Category is Empty
                  </p>
                </div>
              )}
            {userCanEdit ? (
              <div className="p-3">
                <Button
                  variant="outlined"
                  className="text-left min-w-[0] text-sm !border-dashed"
                  loading={isLoading.status && isLoading.id === folder.id}
                  onClick={(event) => {
                    event.stopPropagation()
                    onCreateClick({
                      parent: folder,
                      createType: KB_ITEM_TYPE.DOCUMENT,
                    })
                  }}
                >
                  <span
                    className="line-clamp-1 px-6"
                    data-intercom-target="add-article"
                  >
                    {`Add Article ${folder?.title && ` to ${folder?.title}`}`}
                  </span>
                </Button>
              </div>
            ) : null}
          </div>
        </KBFolder>
      </div>
    ))
  }

  return (
    <>
      {categories?.length === 0 ? (
        <div className="grow p-3 px-4">
          <p className="italic text-gray-500 text-sm">Category is Empty</p>
        </div>
      ) : (
        renderCategories(categories, 1)
      )}
    </>
  )
}

export default KBFolderList
