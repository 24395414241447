import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import {
  FindReactMapsSidePanelQuery,
  type FindReactMapsSidePanelQueryVariables,
} from 'types/graphql'

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'
import { CategoryList } from 'src/components/ProcessMapsEditorCell/ProcessMapsEditorCell'
import { getUncategorisedMaps } from 'src/components/ReactMap/utils'

import ReactMapMobile from '../ReactMapMobile'
import ReactMapSidePanel from '../ReactMapSidePanel'
export const QUERY = gql`
  query FindReactMapsSidePanelQuery($status: LearnerStatus) {
    mapCategoryList: reactMapCategories(status: $status) {
      id
      clientId
      name
      description
      status
      rank
      reactMaps(status: $status) {
        ...ReactMapFragment
      }
    }
    mapList: reactMapsList {
      ...ReactMapFragment
    }
  }

  fragment ReactMapFragment on ReactMap {
    id
    status
    clientId
    name
    description
    isVisible
    reactMapCategoryId
    isTemplate
    rank
  }
`

export type FindReactMapsSidePanelQueryProps = CellSuccessProps<
  FindReactMapsSidePanelQuery,
  FindReactMapsSidePanelQueryVariables
> & {
  selectedMap: number
  setSelectedMap: Dispatch<SetStateAction<number>>
  updateCategoryList: Dispatch<SetStateAction<CategoryList>>
  isMobile: boolean
}

export type ReactMap = FindReactMapsSidePanelQueryProps['mapList'][0]
export type ReactMapCategory =
  FindReactMapsSidePanelQueryProps['mapCategoryList'][0]
export type ReactMapsEditorRefetch =
  FindReactMapsSidePanelQueryProps['queryResult']['refetch']

export const Loading = () => <LoadingSpinner />

export const Empty: FC<FindReactMapsSidePanelQueryProps> = ({
  mapList,
  queryResult: { refetch },
  isMobile,
}) => {
  const [uncategorisedMaps, setUncategorisedMaps] = useState<ReactMap[]>(
    mapList.length === 0 ? [] : getUncategorisedMaps(mapList),
  )

  useEffect(() => {
    setUncategorisedMaps(
      mapList.length === 0 ? [] : getUncategorisedMaps(mapList),
    )
  }, [mapList])

  return isMobile ? (
    <ReactMapMobile
      categoryList={[]}
      uncategorisedMaps={uncategorisedMaps}
      refetch={refetch}
    />
  ) : (
    <ReactMapSidePanel
      categoryList={[]}
      uncategorisedMaps={uncategorisedMaps}
      refetch={refetch}
    />
  )
}

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

export const Success: FC<FindReactMapsSidePanelQueryProps> = ({
  mapCategoryList,
  mapList,
  selectedMap,
  setSelectedMap,
  updateCategoryList,
  queryResult: { refetch },
  isMobile,
}) => {
  const [uncategorisedMaps, setUncategorisedMaps] = useState<ReactMap[]>(
    getUncategorisedMaps(mapList),
  )

  const [mapCategoryListFull, setMapCategoryListFull] = useState<
    ReactMapCategory[]
  >([...mapCategoryList])

  useEffect(() => {
    setUncategorisedMaps(getUncategorisedMaps(mapList))
  }, [mapList])

  useEffect(() => {
    setMapCategoryListFull(mapCategoryList)
  }, [mapCategoryList]) // added dep here if it fucks itself try removing this

  useEffect(() => {
    updateCategoryList(
      mapCategoryList.map((item) => ({
        text: item.name,
        value: item.id,
        label: item.name,
      })),
    )
  }, [mapCategoryList, updateCategoryList])

  return isMobile ? (
    <ReactMapMobile
      refetch={refetch}
      categoryList={mapCategoryListFull}
      uncategorisedMaps={uncategorisedMaps}
      setSelectedMap={setSelectedMap}
      selectedMap={selectedMap}
    />
  ) : (
    <ReactMapSidePanel
      refetch={refetch}
      categoryList={mapCategoryListFull}
      uncategorisedMaps={uncategorisedMaps}
      setSelectedMap={setSelectedMap}
      selectedMap={selectedMap}
    />
  )
}
