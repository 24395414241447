import { useCallback } from 'react'

import type { OutputData } from '@editorjs/editorjs'
import { isNil } from 'ramda'
import type {
  UpdateKnowledgeBaseItemMutation,
  UpdateKnowledgeBaseItemMutationVariables,
  KnowledgeBaseItemStatus,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import type { KBItemSorted } from 'src/components/KnowledgeCell/types'

const UpdateMutation = gql`
  mutation UpdateKnowledgeBaseItemMutation(
    $id: Int!
    $input: UpdateKnowledgeBaseItemInput!
  ) {
    updateKnowledgeBaseItem(id: $id, input: $input) {
      id
    }
  }
`

const useOptimisticUpdateMutation = () => {
  const [updateMutation] = useMutation<
    UpdateKnowledgeBaseItemMutation,
    UpdateKnowledgeBaseItemMutationVariables
  >(UpdateMutation)

  type UpdateProps = {
    kbItemId: number
    title?: string
    url?: string
    text?: string
    document?: OutputData
    status?: KnowledgeBaseItemStatus
    parent?: KBItemSorted
    isGlobal?: boolean
  }
  const update = useCallback(
    async (props: UpdateProps) => {
      const { kbItemId, title, url, text, document, status, parent, isGlobal } =
        props

      const variables: UpdateKnowledgeBaseItemMutationVariables = {
        id: kbItemId,
        input: {
          ...(parent?.id ? { parentId: parent?.id } : { parentId: null }),
          ...(status && { status }),
          ...(!isNil(title) && { title }),
          ...(!isNil(url) && { url }),
          ...(!isNil(text) && { text }),
          ...(!isNil(isGlobal) && { isGlobal }),
          ...(document && { document: document as any }),
        },
      }
      const optimisticResponse: UpdateKnowledgeBaseItemMutation = {
        updateKnowledgeBaseItem: {
          id: kbItemId,
          __typename: 'KnowledgeBaseItem',
          ...variables.input,
        },
      }

      const result = await updateMutation({ variables, optimisticResponse })

      return result
    },
    [updateMutation],
  )

  return update
}

export default useOptimisticUpdateMutation
