import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import EditorjsCodeflask from '@calumk/editorjs-codeflask'
import AttachesTool from '@editorjs/attaches'
import CheckList from '@editorjs/checklist'
import Delimiter from '@editorjs/delimiter'
import type { API, OutputData } from '@editorjs/editorjs'
import type EditorJS from '@editorjs/editorjs'
import Embed from '@editorjs/embed'
import Header from '@editorjs/header'
import ImageTool from '@editorjs/image'
import InlineCode from '@editorjs/inline-code'
import Marker from '@editorjs/marker'
import NestedList from '@editorjs/nested-list'
import Quote from '@editorjs/quote'
import Raw from '@editorjs/raw'
import SimpleImage from '@editorjs/simple-image'
import Table from '@editorjs/table'
import Underline from '@editorjs/underline'
import Warning from '@editorjs/warning'
import { EditorCore } from '@react-editor-js/core'
import { SUPPORTED_FILE_MIME_TYPES } from 'api/src/common/enums'
import { createReactEditorJS } from 'react-editor-js'

import useUploadStorageObject from 'src/lib/hooks/StorageObject/useUploadStorageObject'
import { getAcceptedTypesForInput } from 'src/lib/hooks/StorageObject/utils'
import { useAuth } from 'src/Providers'

export type DocumentUpload = {
  success: number
  file: { url: string; storageObjectId: number }
}
interface Props {
  readerMode?: boolean
  defaultValue?: OutputData
  setData: Dispatch<SetStateAction<OutputData>>
}

const DocumentEditor: React.FC<Props> = ({
  readerMode = false,
  defaultValue,
  setData,
}) => {
  const { currentUser } = useAuth()
  const clientId = currentUser.parentData.id

  const ReactEditorJS = createReactEditorJS()

  const editorCore = useRef<EditorCore>(null)
  const [ready, setReady] = useState(false)

  const getEditor = () =>
    editorCore.current.dangerouslyLowLevelInstance as EditorJS

  useEffect(() => {
    if (ready) {
      const editor = getEditor()
      editor.readOnly?.toggle(readerMode)
    }
  }, [readerMode])

  const handleInitialize = useCallback((instance: EditorCore) => {
    editorCore.current = instance
    setReady(true)
  }, [])

  const handleReady = () => {
    const editor = getEditor()
    editor.readOnly.toggle(readerMode)
  }

  const handleSave = useCallback(async (_api: API, _event: CustomEvent) => {
    const savedData = await editorCore.current.save()
    setData(savedData)
  }, [])

  const imageUploadFileTypes = [SUPPORTED_FILE_MIME_TYPES.IMAGE]

  const [uploadImage] = useUploadStorageObject({
    prefixes: [`${clientId}`, 'knowledge-base', 'document'],
    isGlobal: false,
    allowedFileMimeTypes: imageUploadFileTypes,
  })

  const fileUploadFileTypes = [
    SUPPORTED_FILE_MIME_TYPES.IMAGE,
    SUPPORTED_FILE_MIME_TYPES.VIDEO,
    SUPPORTED_FILE_MIME_TYPES.APPLICATION,
    SUPPORTED_FILE_MIME_TYPES.TEXT,
  ]

  const [uploadFile] = useUploadStorageObject({
    prefixes: [`${clientId}`, 'knowledge-base', 'document'],
    isGlobal: false,
    allowedFileMimeTypes: fileUploadFileTypes,
  })

  const tools = {
    image: {
      class: ImageTool,
      config: {
        types: getAcceptedTypesForInput(imageUploadFileTypes),
        uploader: {
          async uploadByFile(file) {
            const result = await uploadImage({ file })
            return {
              success: 1,
              file: {
                url: result.downloadUrl,
                storageObjectId: result.id,
              },
            }
          },
        },
      },
    },
    attaches: {
      class: AttachesTool,
      config: {
        types: getAcceptedTypesForInput(fileUploadFileTypes),
        uploader: {
          async uploadByFile(file) {
            const result = await uploadFile({ file })
            return {
              success: 1,
              file: {
                url: result.downloadUrl,
                storageObjectId: result.id,
              },
            }
          },
        },
      },
    },
    embed: Embed,
    table: Table,
    list: NestedList,
    warning: Warning,
    raw: Raw,
    header: Header,
    quote: Quote,
    marker: Marker,
    checklist: CheckList,
    delimiter: Delimiter,
    inlineCode: InlineCode,
    simpleImage: SimpleImage,
    underline: Underline,
    code: EditorjsCodeflask,
  }

  return (
    <div
      className={`overflow-x-auto ${
        readerMode ? 'editorJsReadonly' : 'bg-white'
      }`}
      data-intercom-target="document-editor"
      data-testid="document-editor"
    >
      <ReactEditorJS
        onInitialize={handleInitialize}
        onReady={handleReady}
        onChange={handleSave}
        tools={tools}
        defaultValue={defaultValue}
      />
    </div>
  )
}

export default DocumentEditor
