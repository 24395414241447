export const CreateMutation = gql`
  mutation CreateKnowledgeBaseItemMutation(
    $input: CreateKnowledgeBaseItemInput!
  ) {
    createKnowledgeBaseItem(input: $input) {
      id
      title
      url
      type
      createdBy
      createdAt
      updatedBy
      updatedAt
      parentId
      isGlobal
      status
    }
  }
`
