import { useCallback } from 'react'

import type { OperationVariables } from '@apollo/client'
import type { TypedDocumentNode } from '@graphql-typed-document-node/core'
import type { DocumentNode } from 'graphql'
import type {
  CreateKnowledgeBaseItemInput,
  CreateKnowledgeBaseItemMutation,
  CreateKnowledgeBaseItemMutationVariables,
  KnowledgeBaseItemStatus,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { updateCache } from 'src/apolloCache/functions/updateCache'

import { CreateMutation } from '../Queries/queries'

const useCreateMutation = (params: {
  refetchQuery: DocumentNode | TypedDocumentNode<any, OperationVariables>
  statuses: KnowledgeBaseItemStatus[]
  asAdmin: boolean
}) => {
  const { refetchQuery, statuses, asAdmin } = params
  const [createMutation] = useMutation<
    CreateKnowledgeBaseItemMutation,
    CreateKnowledgeBaseItemMutationVariables
  >(CreateMutation, {
    update: (_, { data: { createKnowledgeBaseItem } }) =>
      updateCache({
        query: refetchQuery,
        mutationResult: createKnowledgeBaseItem,
        updatedField: 'kbItems',
        queryVariables: {
          statuses,
          asAdmin,
        },
      }),
  })

  const create = useCallback(
    async (input: CreateKnowledgeBaseItemInput) => {
      const variables: CreateKnowledgeBaseItemMutationVariables = {
        input,
      }

      const result = await createMutation({ variables })
      return result
    },
    [createMutation],
  )

  return create
}

export default useCreateMutation
