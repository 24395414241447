import React, { useEffect, useMemo, useState } from 'react'

import Stack from '@mui/material/Stack'
import ReactFlow, {
  Background,
  BackgroundVariant,
  ConnectionLineType,
  ConnectionMode,
  Edge,
  Node,
  Panel,
  useEdgesState,
} from 'reactflow'
import { useBoolean } from 'usehooks-ts'

import Accordion from 'src/components/Library/Accordion/Accordion'
import ReactMapControlPanelUpperToolbar from 'src/components/ReactMap/ReactMapControlPanelUpperToolbar'
import ReactMapCustomNode from 'src/components/ReactMap/ReactMapCustomNode'
import ReactMapGroupNode from 'src/components/ReactMap/ReactMapGroupNode'
import ReactMapLegend from 'src/components/ReactMap/ReactMapLegend'
import { defaultEdgeOptions } from 'src/components/ReactMap/utils'
import useReactFlowStore from 'src/lib/stores/reactMapStores'

const ReactMapPreview = ({ mapItem, isMobile }) => {
  const settingsModal = useBoolean(false)
  const nodes = useReactFlowStore((state) => state.nodes)
  const setNodes = useReactFlowStore((state) => state.setNodes)
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge[]>(
    JSON.parse(mapItem.connectors as string) as Record<string, any> as Edge[],
  )
  const [legend, setLegend] = useState([])
  const [reactFlowInstance, setReactFlowInstance] = useState(null)
  const nodeTypes = useMemo(
    () => ({
      ReactMapCustomNode: ReactMapCustomNode,
      ReactMapGroupNode: ReactMapGroupNode,
    }),
    [],
  )
  useEffect(() => {
    const newNodes = JSON.parse(mapItem.nodes as string) as Node[]
    const newNotesNotSelected = newNodes.map((node) => {
      return { ...node, selected: false }
    })
    setLegend(JSON.parse(mapItem.legend as string))
    setNodes(newNotesNotSelected)
  }, [mapItem])
  const proOptions = { account: 'paid-pro', hideAttribution: true }

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={() => {}}
      onEdgesChange={() => {}}
      multiSelectionKeyCode="Shift"
      onConnect={() => {}}
      minZoom={0}
      maxZoom={5}
      defaultEdgeOptions={defaultEdgeOptions}
      onInit={setReactFlowInstance}
      connectionLineType={ConnectionLineType.SmoothStep}
      nodeTypes={nodeTypes}
      connectionMode={ConnectionMode.Loose}
      proOptions={proOptions}
      fitView={edges.length > 1 ? true : false}
      elevateEdgesOnSelect
      elevateNodesOnSelect
      onDrop={() => {}}
      onNodeDrag={() => {}}
      onNodeDragStop={() => {}}
      onDragOver={() => {}}
      snapToGrid={true}
      snapGrid={[5, 5]}
      edgesUpdatable={false}
      edgesFocusable={false}
      nodesDraggable={false}
      nodesConnectable={false}
      nodesFocusable={false}
      elementsSelectable={false}
    >
      <Background
        variant={BackgroundVariant.Dots}
        gap={12}
        size={1}
        className={'bg-gray-50'}
      />
      {!isMobile && (
        <ReactMapControlPanelUpperToolbar
          isEditing={false}
          setIsEditing={() => {}}
          saveMap={() => {}}
          mapName={mapItem.name}
          mapId={mapItem.id}
          cancelSavingMap={() => {}}
          duplicateMap={() => {}}
          fullScreenMap={true}
          setFullScreenMap={() => {}}
          duplicateMapLoading={false}
          deleteReactMapLoading={false}
          saveMapLoading={false}
          deleteReactMap={() => {}}
          legendRef={undefined}
          settingsModal={settingsModal}
          mapInTasks={undefined}
          isPublished={mapItem.status === 'PUBLISHED'}
          publishMap={() => {}}
          publishingMapLoading={false}
          deselectNodes={() => {}}
          undoAction={() => {}}
          redoAction={() => {}}
          disableEditTools={true}
          linkedWorkflowsRows={[]}
        />
      )}
      {!isMobile && legend.length > 0 && (
        <Panel
          position="top-right"
          className={
            'max-h-[90%] w-[20%] overflow-y-auto rounded-lg border border-gray-200 bg-gray-50 p-2 shadow-sm'
          }
        >
          <Stack spacing={2}>
            <Accordion
              title="Legend"
              expanded={true}
              customstyle={'bg-white'}
              borderstyle={'rounded-lg border border-gray-600'}
            >
              <div>
                <ReactMapLegend
                  legend={legend}
                  setLegend={() => {}}
                  saveMap={() => {}}
                  mapId={mapItem.id}
                  isEditing={false}
                  saveMapLoading={false}
                />
              </div>
            </Accordion>
          </Stack>
        </Panel>
      )}
    </ReactFlow>
  )
}

export default ReactMapPreview
