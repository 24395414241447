export const CREATE_REACT_MAP = gql`
  mutation CreateReactMapEditorMutation($input: CreateReactMapInput!) {
    createReactMap(input: $input) {
      id
      createdBy
      updatedBy
      clientId
      name
      description
      diagramData
    }
  }
`

export const UPDATE_REACT_MAP = gql`
  mutation UpdateReactMapMutation($input: UpdateReactMapInput!, $id: Int!) {
    updateReactMap(input: $input, id: $id) {
      id
      updatedBy
      status
      name
      description
      nodes
      connectors
      reactMapCategoryId
      isTemplate
    }
  }
`

export const DELETE_REACT_MAP = gql`
  mutation DeleteReactMapMutation($id: Int!) {
    deleteReactMap(id: $id) {
      id
    }
  }
`

export const CONVERT_LEARNER_TASKS_TO_REACT_MAPS = gql`
  mutation convertLearnerTasksToReactMaps($clientIds: [Int!]!) {
    convertLearnerMapsToReactMaps(clientIds: $clientIds) {
      id
    }
  }
`
