import React, { FC } from 'react'

import { Stack } from '@mui/material'

import { legendType } from 'src/components/ReactMap/ReactMapControlPanel'
import ReactMapLegendItem from 'src/components/ReactMap/ReactMapLegendItem'

export interface ReactMapLegendProps {
  legend: legendType[]
  setLegend: (legend: legendType[]) => void
  isEditing: boolean
  saveMap: (mapId: number, refetch: boolean) => void
  saveMapLoading: boolean
  mapId: number
}

const ReactMapLegend: FC<ReactMapLegendProps> = ({
  legend,
  setLegend,
  saveMap,
  isEditing,
  saveMapLoading,
  mapId,
}) => {
  const handleUpdate = (index: number, newLabel: string) => {
    const newLegend = [...legend]
    newLegend[index].label = newLabel
    setLegend(newLegend)
    saveMap(mapId, true)
  }

  return (
    <>
      {legend && (
        <Stack>
          {legend.map((item, key) => (
            <ReactMapLegendItem
              legendItem={item}
              key={key}
              index={key}
              isEditing={isEditing}
              onUpdate={handleUpdate}
              saveMapLoading={saveMapLoading}
            />
          ))}
        </Stack>
      )}
    </>
  )
}

export default ReactMapLegend
