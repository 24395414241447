import { useEffect, useMemo, useState } from 'react'

import { Autocomplete, Box, TextField } from '@mui/material'
import { range } from 'ramda'

import type { KBItemSorted } from 'src/components/KnowledgeCell/types'

interface Props {
  size?: 'small' | 'medium'
  flatCategories: KBItemSorted[]
  selectedCategory: KBItemSorted
  onSelect: (_arg: KBItemSorted) => void
  selectedKbEditItem: KBItemSorted
  disableClearable?: boolean
}

interface KBItemWithLevel extends KBItemSorted {
  level: number
}

const getLevel = (item: KBItemSorted, allItems: KBItemSorted[]) => {
  let level = 0
  let parent = allItems.find((x) => item.parentId === x.id)
  while (parent) {
    level++
    parent = allItems.find((x) => parent.parentId === x.id)
  }
  return level
}

const CategorySearch = ({
  size = 'small',
  selectedCategory,
  flatCategories,
  onSelect,
  selectedKbEditItem,
  disableClearable = false,
}: Props) => {
  const removedIds = []
  const [options, setOptions] = useState(
    flatCategories.map((category) => {
      const level = getLevel(category, flatCategories)
      return {
        ...category,
        level,
      }
    }),
  )
  //traverse flat tree and remove all children of selected category
  const filterTree = (nodeId: number) => {
    //find id in array
    for (const item of flatCategories.filter(
      (item) => item.parentId === nodeId,
    )) {
      removedIds.push(item.id)
      filterTree(item.id)
    }
  }
  useEffect(() => {
    if (selectedKbEditItem) {
      removedIds.push(selectedKbEditItem.id)
      filterTree(selectedKbEditItem.id)
      setOptions(
        options.filter((item) => {
          return !removedIds.includes(item.id)
        }),
      )
    }
  }, [selectedKbEditItem])

  const defaultValue = useMemo(() => {
    return options.find((x) => x.id === selectedCategory?.id)
  }, [options, selectedCategory])

  return (
    <Autocomplete
      id="knowledge-base-category-select"
      onChange={(_, value: KBItemWithLevel) => onSelect(value)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      size={size}
      defaultValue={defaultValue}
      options={options.filter((item) => !removedIds.includes(item.id))}
      autoHighlight
      getOptionLabel={(option: KBItemWithLevel) => option.title}
      disableClearable={disableClearable}
      renderOption={(props, option /*, { inputValue } */) => {
        return (
          <Box
            {...props}
            component="li"
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
            key={option.id}
          >
            {range(0, option.level * 2).map((idx) => (
              <span key={idx}>&nbsp;</span>
            ))}
            {!!option.level && '└─'}
            {option.title}
          </Box>
        )
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
            }}
            label="Parent Category"
          />
        )
      }}
    />
  )
}

export default CategorySearch
