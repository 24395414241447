import React, { FC, useEffect, useMemo, useState } from 'react'

import { DialogContent, TextField } from '@mui/material'
import { PROD_CLIENT } from 'api/src/common/enums'
import { toSentenceCase } from 'api/src/common/utils'
import { ACLPrincipalType, TableName } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'

import type { KBItem, KBItemSorted } from 'src/components/KnowledgeCell/types'
import Switch from 'src/components/Library/Switch'
import useACL from 'src/lib/hooks/ACL/useACL'
import { useAuth } from 'src/Providers'

import Modal from '../../Modal/Modal'

import CategorySearch from './CategorySearch'

export enum ModalState {
  CREATE = 'create',
  EDIT = 'edit',
}

interface Props {
  modalMode: ModalState
  kbItems: KBItemSorted[]
  selectedKbEditItem: KBItem
  categoryModalOpen: boolean
  setCategoryModalOpen: (value: boolean) => void
  handleCreateSave: (args: any) => Promise<{ id: number }>
  handleEditSave: (args: any) => void
}

const CreateEditCategoryModal: FC<Props> = ({
  modalMode,
  kbItems,
  selectedKbEditItem,
  categoryModalOpen,
  setCategoryModalOpen,
  handleCreateSave,
  handleEditSave,
}) => {
  const { currentUser } = useAuth()

  const parentItem = useMemo(() => {
    if (modalMode === ModalState.CREATE) {
      return null // no parent for new items or if a category is selected
    } else {
      // Find the parent item in the list
      const parent =
        kbItems.find((item) => item.id === selectedKbEditItem?.parentId) || null
      return parent
    }
  }, [kbItems, modalMode, selectedKbEditItem])

  const [title, setTitle] = useState(selectedKbEditItem?.title || '')
  const [parentCategory, setParentCategory] = useState<KBItemSorted>(parentItem)

  const [isGlobal, setIsGlobal] = useState(
    selectedKbEditItem?.isGlobal || false,
  )
  const [saving, setSaving] = useState(false)

  const [categoryNameError, setCategoryNameError] = useState<string>('')

  const isStafflink =
    currentUser.membershipData.clientId === PROD_CLIENT.STAFFLINK
  const isSuperAdmin = currentUser.userData.role === 'SUPERADMIN'
  const isTopLevelItem =
    !!selectedKbEditItem &&
    selectedKbEditItem.id &&
    selectedKbEditItem.parentId === null

  const canShareGlobal = isSuperAdmin && isTopLevelItem && isStafflink

  const { AccessControlList, saveACL, deleteACL } = useACL({
    resourceType: 'KnowledgeBaseItem' as TableName,
    resourceId: selectedKbEditItem?.id,
    principalTypes: [
      'MEMBERSHIP',
      'MEMBERSHIPGROUP',
      ...(canShareGlobal ? ['CLIENT' as ACLPrincipalType] : []),
    ],
  })

  const handleClose = () => {
    setCategoryModalOpen(false)
  }

  const handleSave = async () => {
    setSaving(true)

    // Validate title before proceeding with save
    if (!title.trim()) {
      setCategoryNameError('Title cannot be empty.')
      setSaving(false)
      return // Exit early if the title is invalid
    }

    // user can create a new category but also can create under an existing category
    // or edit an existing category or sub category
    // or remove the sub category, making it a top level category
    if (modalMode === ModalState.EDIT) {
      const editPromise = handleEditSave({
        id: selectedKbEditItem.id,
        title,
        parent: parentCategory,
        isGlobal,
      })
      await Promise.all([
        editPromise,
        ...(isGlobal ? [deleteACL()] : [saveACL()]),
      ])
    } else {
      const result = await handleCreateSave({
        title,
        createType: 'CATEGORY',
        parent: parentCategory,
        isGlobal,
      })

      if (!isGlobal && result) {
        await saveACL({ resourceId: result.id })
        navigate(routes.knowledgeWithId({ id: result.id }))
      }
    }
    setSaving(false)
    handleClose() //  close the modal
  }

  useEffect(() => {
    // Clear error when a valid title is entered
    if (title.trim() && categoryNameError) {
      setCategoryNameError('')
    }
  }, [title, categoryNameError])

  const topLevelCategories = useMemo(
    () =>
      kbItems
        ?.filter(
          (item) => item.type === 'CATEGORY' && item.parentId === null, // Only top-level categories
        )
        .sort((a, b) => a.title.localeCompare(b.title)), // Sort by name in ascending order
    [kbItems],
  )

  const categoriesWithChild = useMemo(() => {
    return kbItems?.reduce((categoryOptions, currentCategory) => {
      if (
        currentCategory.type === 'CATEGORY' &&
        currentCategory.parentId === null
      ) {
        // Add the top-level category object
        categoryOptions.push(currentCategory)

        // Add the first-level child category objects
        for (const child of kbItems) {
          if (
            child.type === 'CATEGORY' &&
            child.parentId === currentCategory.id
          ) {
            categoryOptions.push(child)
          }
        }
      }

      return categoryOptions
    }, [])
  }, [kbItems])

  const kbItemType = toSentenceCase(
    !selectedKbEditItem ? 'category' : selectedKbEditItem?.type,
  )

  // only show top level categories when creating a new category or if the selected item is a category
  const categories =
    modalMode === ModalState.CREATE || selectedKbEditItem.type === 'CATEGORY'
      ? topLevelCategories
      : categoriesWithChild

  const disableClearable =
    selectedKbEditItem?.type === 'ARTICLE' ||
    (selectedKbEditItem?.type === 'DOCUMENT' && modalMode === ModalState.EDIT)

  return (
    <Modal
      open={categoryModalOpen}
      onClose={handleClose}
      dialogClassName={'max-w-2xl'}
      childClassName="p-2"
      title={
        modalMode === ModalState.CREATE
          ? 'New ' + kbItemType
          : 'Edit ' + kbItemType + ': ' + title
      }
      footerVisible
      confirmText={modalMode === ModalState.CREATE ? 'Create' : 'Save'}
      onCancel={handleClose}
      onConfirm={handleSave}
      loading={saving}
      confirmDisabled={!!categoryNameError}
    >
      <DialogContent className="grid gap-2">
        <h4 className="text-md pb-2 font-semibold text-gray-800">
          {kbItemType} Settings
        </h4>
        <div className="grid gap-4">
          <TextField
            fullWidth
            label={kbItemType + ' Title'}
            variant="outlined"
            margin="dense"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value)
            }}
            error={categoryNameError?.length < 2 ? false : true}
            helperText={categoryNameError}
            data-intercom-target="category-title-input"
            data-testid="category-title-input"
          />
          <CategorySearch
            size="medium"
            {...{
              selectedCategory: parentCategory,
              flatCategories: categories,
              onSelect: setParentCategory,
              selectedKbEditItem,
              disableClearable: disableClearable,
            }}
          />
        </div>
        <h4 className="text-md pb-2 pt-5 font-semibold text-gray-800">
          Visability Settings
        </h4>
        {(canShareGlobal || isGlobal) && (
          <div className="flex items-center justify-start">
            <Switch
              data-testid="access-control-list-restrict-is-global"
              checked={isGlobal}
              disabled={!isSuperAdmin}
              onChange={(e) => setIsGlobal(e.target.checked ? true : false)}
            />
            <span>Global (Share with all clients)</span>
          </div>
        )}
        {!isGlobal && (
          <div className="pt-1">
            <AccessControlList />
          </div>
        )}
      </DialogContent>
    </Modal>
  )
}

export default CreateEditCategoryModal
