import { FC } from 'react'

import { FLAG_FEATURES } from 'api/src/common/enums'
import type { MembershipRole } from 'types/graphql'

import { navigate, routes, useLocation } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import KnowledgeCell, {
  KnowledgeBaseItemStatus,
} from 'src/components/KnowledgeCell'
import FeatureDenied from 'src/components/Library/FeatureDenied/FeatureDenied'
import PageHeader from 'src/components/PageHeader/PageHeader'
import FeatureToggle from 'src/lib/hooks/LiveFeatureFlag/FeatureToggle'
import { useAuth } from 'src/Providers'

export const KB_ADMIN_ROLES: MembershipRole[] = ['OWNER', 'ADMIN', 'EDITOR']

const KnowledgePage: FC<{ id: string }> = ({ id }) => {
  const { currentUser } = useAuth()

  const idParsed: number = parseInt(id) || null
  const location = useLocation()
  const isEditUrl = location.pathname
    .toLowerCase()
    .includes(routes.knowledgeEditWithId({ id: idParsed }))
  const defaultStatuses = [KnowledgeBaseItemStatus.PUBLISHED]
  const isAdmin = KB_ADMIN_ROLES.includes(currentUser.membershipData.role)

  if (!isAdmin && isEditUrl) {
    toast.error('You do not have permission to edit this item')
    navigate(routes.knowledgeWithId({ id: idParsed }))
  }

  const statuses = !isAdmin
    ? defaultStatuses
    : [KnowledgeBaseItemStatus.PUBLISHED, KnowledgeBaseItemStatus.DRAFT]

  return (
    <>
      <Metadata title="Knowledge Base" description="Knowledge Base" />
      <div className="flex h-screen flex-col">
        <PageHeader
          title="Knowledge Base"
          parentDataTestId="knowledge-page-title"
        />
        <FeatureToggle
          feature={FLAG_FEATURES.KNOWLEDGE_BASE}
          InactiveComponent={<FeatureDenied />}
        >
          <div className="flex h-full flex-col overflow-y-auto">
            <KnowledgeCell
              id={idParsed}
              statuses={statuses}
              asAdmin={isAdmin}
            />
          </div>
        </FeatureToggle>
      </div>
    </>
  )
}

export default KnowledgePage
