import React, { FC, useEffect, useRef, useState } from 'react'

import { ArrowRightOnRectangleIcon } from '@heroicons/react/20/solid'
import { Tooltip } from '@mui/material'
import Stack from '@mui/material/Stack'
import { drag } from 'd3-drag'
import { select } from 'd3-selection'
import {
  Handle,
  NodeProps,
  NodeResizer,
  Position,
  useStore,
  useUpdateNodeInternals,
} from 'reactflow'

import Button from 'src/components/Library/Button/Button'
import ReactMapShape from 'src/components/ReactMap/ReactMapShape'
import useDetachNodes from 'src/components/ReactMap/useDetachNodes'
import './index.css'
import { lineShapes } from 'src/components/ReactMap/utils'
import useReactFlowStore from 'src/lib/stores/reactMapStores'

const ReactMapCustomNode: FC<NodeProps> = ({ data, id, selected }) => {
  const rotateControlRef = useRef(null)
  const updateNodeInternals = useUpdateNodeInternals()
  const [rotation, setRotation] = useState(0)
  const [matchedSearchTerm, setMatchedSearchTerm] = useState<boolean>(false)
  const detachNodes = useDetachNodes()
  const [editMode, setEditMode] = useState(false)
  const hasParent = useStore(
    (store) => !!store.nodeInternals.get(id)?.parentNode,
  )

  const onDetach = () => detachNodes([id])
  const searchText = useReactFlowStore((state) => state.searchText) // State of the search text
  const isEditing = useReactFlowStore((state) => state.isEditing)

  const handleStyle = {
    opacity: 100,
    background: '#FFFFFF',
    width: selected ? 10 : 0,
    height: selected ? 10 : 0,
    border: selected ? '1px solid #000000' : '',
  }

  useEffect(() => {
    const searchTextToLower = searchText.toLowerCase()
    const textToSearch = (data?.label ?? '').toLowerCase()

    if (searchTextToLower === '') {
      setMatchedSearchTerm(false)
    } else {
      if (textToSearch.includes(searchTextToLower)) {
        setMatchedSearchTerm(true)
      } else {
        setMatchedSearchTerm(false)
      }
    }
  }, [searchText])

  useEffect(() => {
    if (!rotateControlRef.current) {
      return
    }

    const selection = select(rotateControlRef.current)
    const dragHandler = drag().on('drag', (evt) => {
      const dx = evt.x - 100
      const dy = evt.y - 100
      const rad = Math.atan2(dx, dy)
      const deg = rad * (180 / Math.PI)
      setRotation(180 - deg)
      data.rotation = deg
      updateNodeInternals(id)
    })

    selection.call(dragHandler)
  }, [id, updateNodeInternals])

  useEffect(() => {
    setRotation(180 - data.rotation)
    updateNodeInternals(id)
  }, [])

  const resizeNode = (_e, p) => {
    data.width = p.width
    data.height = p.height
  }

  return (
    <div
      style={{
        transform: `rotate(${Math.ceil(rotation / 1) * 1}deg)`,
      }}
      className={'node'}
    >
      <div className="relative">
        {selected && (
          <NodeResizer
            onResize={(event, params) => resizeNode(event, params)}
            minWidth={lineShapes.includes(data?.shape) ? 10 : 10}
            minHeight={lineShapes.includes(data?.shape) ? 10 : 10}
            handleStyle={handleStyle}
          />
        )}
        <div
          ref={rotateControlRef}
          className={`nodrag block ${
            selected ? 'rotateHandle' : 'rotateHandleUnselected'
          }`}
        />
        <ReactMapShape
          width={data?.width}
          height={data?.height}
          shape={data?.shape}
          shapeColor={data?.shape === 'text' ? '#00000000' : data?.shapeColor}
          strokeColor={
            matchedSearchTerm
              ? '#4F46E5'
              : data?.shape === 'text'
                ? '#00000000'
                : data?.strokeColor
          }
          pulseStroke={matchedSearchTerm}
          strokeWidth={matchedSearchTerm ? '6' : '2'}
          labelText={data?.label}
          labelColor={data?.labelColor}
          url={data?.url}
          editMode={editMode}
          setEditMode={setEditMode}
          onDoubleClick={() => setEditMode(true)}
          nodeId={id}
          fontSize={data?.fontSize}
        />
        <Handle
          id="top"
          className={`bg-white border border-black ${
            selected ? 'w-3 h-3' : 'w-1 h-1'
          } ${!isEditing && 'opacity-0 pointer-events-none'}`}
          position={Position.Top}
          type="source"
        />
        <Handle
          id="right"
          className={`bg-white border border-black ${
            selected ? 'w-3 h-3' : 'w-0 h-0'
          } ${!isEditing && 'opacity-0 pointer-events-none'}`}
          position={Position.Right}
          type="source"
        />
        <Handle
          id="bottom"
          className={`bg-white border border-black ${
            selected ? 'w-3 h-3' : 'w-1 h-1'
          } ${!isEditing && 'opacity-0 pointer-events-none'}`}
          position={Position.Bottom}
          type="source"
        />
        <Handle
          id="left"
          className={`bg-white border border-black ${
            selected ? 'w-3 h-3' : 'w-1 h-1'
          } ${!isEditing && 'opacity-0 pointer-events-none'}`}
          position={Position.Left}
          type="source"
        />
      </div>
      <Stack
        direction="row"
        spacing={1}
        className="justify-end items-end content-end"
      >
        <Tooltip title={'Detach'}>
          <div>
            {hasParent && selected && (
              <Button
                fullWidth={false}
                variant="outlined"
                className="min-w-[0] p-1 rounded-full"
                onClick={onDetach}
              >
                <ArrowRightOnRectangleIcon className="w-3 h-3" />
              </Button>
            )}
          </div>
        </Tooltip>
      </Stack>
    </div>
  )
}

export default ReactMapCustomNode
