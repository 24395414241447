import { FC, useEffect, useState } from 'react'

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PhotoIcon,
} from '@heroicons/react/24/outline'
import { Tooltip } from '@mui/material'
import { useReadLocalStorage } from 'usehooks-ts'

import { navigate, routes } from '@redwoodjs/router'

import FilterNavTabs from 'src/components/FilterNavTabs/FilterNavTabs'
import { KB_ITEM_TYPE } from 'src/components/KnowledgeCell'
import SearchSelect from 'src/components/KnowledgeCell/SearchSelect'
import { KBItemSorted } from 'src/components/KnowledgeCell/types'
import IconButton from 'src/components/Library/IconButton'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import { useAuth } from 'src/Providers'
import { getNewestCreatedContent } from 'src/Util'

import KnowledgeBaseArticleLink from './KnowledgeBaseArticleLink'
import KnowledgeBaseCategories, {
  KbItemSortedWithCounts,
} from './KnowledgeBaseCategories'

const getTopLevelCategories = (
  kbItems: KBItemSorted[],
): KbItemSortedWithCounts[] => {
  const modifiedKbItems = kbItems
    .filter((kbItem) => kbItem.type === 'CATEGORY' && !kbItem.parentId)
    .map((kbItem) => {
      // For VIEW purposes - anything but a CATEGORY is classed as an "article" (Document, Link, Article)
      const articleCount = kbItems.filter(
        (item) =>
          item.parentId === kbItem.id && item.type !== KB_ITEM_TYPE.CATEGORY,
      ).length

      const categoryCount = kbItems.filter(
        (item) =>
          item.parentId === kbItem.id && item.type === KB_ITEM_TYPE.CATEGORY,
      ).length

      return { ...kbItem, articleCount, categoryCount }
    })

  return [...modifiedKbItems].sort((a, b) => {
    return a?.title?.toLowerCase() > b?.title?.toLowerCase() ? 1 : -1
  })
}

interface KnowledgeBaseHomeProps {
  kbItems: KBItemSorted[]
  kbListExpanded: boolean
  toggleSidebar: () => void
  canEdit: boolean
}

enum KbResourceTab {
  LATEST_CONTENT = 'Latest Content',
  RECENTLY_VISITED = 'Recently Visited',
  RECENTLY_SEARCHED = 'Recently Searched', // Future Item - not yet implemented
  FOR_YOU = 'For You', // Future Item - not yet implemented
}

interface KbTab {
  title: KbResourceTab
}
export const contentListLimit = 8

const KnowledgeBaseHome: FC<KnowledgeBaseHomeProps> = ({
  kbItems,
  kbListExpanded,
  toggleSidebar,
  canEdit,
}) => {
  const { currentUser } = useAuth()
  const { trackEvent } = useAnalytics()

  const recentArticlesIds: number[] =
    useReadLocalStorage(
      `memberId-${currentUser.membershipData?.id}-knowledgeBase-recentArticles`,
    ) ?? []

  const [recentArticles, setRecentArticles] = useState<KBItemSorted[]>([])
  const [latestContent, setLatestContent] = useState<KBItemSorted[]>(
    getNewestCreatedContent<KBItemSorted>(kbItems, contentListLimit),
  )

  const [topLevelCategories, setTopLevelCategories] = useState<
    KbItemSortedWithCounts[]
  >(getTopLevelCategories(kbItems))

  // Set Nav Tab Filter counts
  const navTabItems: KbTab[] = [
    { title: KbResourceTab.LATEST_CONTENT },
    {
      title: KbResourceTab.RECENTLY_VISITED,
    },
  ]

  const [tabSelected, setTabSelected] = useState(
    navTabItems.findIndex((tab) => tab.title === KbResourceTab.LATEST_CONTENT),
  )

  const setTabSelectedCallBack = (tabTitle) => {
    trackEvent('Knowledge Base', `Clicked ${tabTitle} Tab`)
    setTabSelected(navTabItems.findIndex((tab) => tab.title === tabTitle))
  }

  useEffect(() => {
    const foundArticles = recentArticlesIds
      .map((id) => kbItems.find((item) => item.id === id) ?? null)
      .filter((item) => item)

    setRecentArticles(foundArticles)
  }, [])

  useEffect(() => {
    setLatestContent(
      getNewestCreatedContent<KBItemSorted>(kbItems, contentListLimit),
    )
    setTopLevelCategories(getTopLevelCategories(kbItems))
  }, [kbItems])

  const kbImageUrl = currentUser?.parentData?.knowledgeBaseUrl

  return (
    <div className="relative w-full overflow-visible">
      {currentUser.parentData?.knowledgeBaseUrl ? (
        <div className="relative w-full">
          <div
            className="flex h-full w-full items-center bg-cover bg-center bg-no-repeat py-16"
            style={{
              backgroundImage: `url(${kbImageUrl})`,
            }}
          >
            <div className="relative z-10 mx-auto w-full max-w-[100ch] p-6 text-center">
              <p className="mb-2 text-4xl text-white">Welcome</p>
              <p className="mb-6 text-xl text-white">
                Have Questions? Feel free to check out the topics and articles
                here.
              </p>
              <SearchSelect canEdit={canEdit} />
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-[#4f47c8] py-16">
          <div className="relative z-10 mx-auto max-w-[100ch] p-6 text-center">
            <p className="mb-2 text-4xl text-white">Welcome</p>
            <p className="mb-6 text-xl text-white">
              Have Questions? Feel free to check out the topics and articles
              here.
            </p>
            <SearchSelect canEdit={canEdit} />
          </div>
        </div>
      )}

      <div className="absolute top-0 z-10 pl-4 pt-5">
        <div
          className={`flex items-center ${kbListExpanded ? 'relative' : 'absolute'}`}
        >
          <Tooltip
            arrow
            placement="top"
            title={
              kbListExpanded
                ? 'Hide Knowledge Base List'
                : 'Show Knowledge Base List'
            }
          >
            <div>
              <IconButton
                className={
                  'flex-shrink rounded-lg bg-gray-100/50 p-2 hover:!bg-gray-200'
                }
                onClick={() => {
                  toggleSidebar()
                }}
              >
                {kbListExpanded ? (
                  <ChevronLeftIcon className="h-4 w-4" />
                ) : (
                  <ChevronRightIcon className="h-4 w-4" />
                )}
              </IconButton>
            </div>
          </Tooltip>
        </div>
      </div>

      {/* Only users with admin roles can alter kb header image */}
      {canEdit && (
        <div className="absolute right-0 top-0 z-10 pr-4 pt-5">
          <div className="relative flex items-center">
            <Tooltip
              arrow
              placement="top"
              title={
                kbImageUrl
                  ? 'Change Knowledge Base Image'
                  : 'Add Knowledge Base Image'
              }
            >
              <div>
                <IconButton
                  className={
                    'flex-shrink rounded-lg bg-gray-100/50 p-2 hover:!bg-gray-200'
                  }
                  onClick={() => {
                    navigate(
                      routes.settingsClientConfiguration() +
                        '#knowledgeBaseImage',
                    )
                  }}
                >
                  <PhotoIcon className="h-4 w-4" />
                </IconButton>
              </div>
            </Tooltip>
          </div>
        </div>
      )}

      <div className="mx-auto max-w-[100ch] p-6 pt-16">
        <div className="mb-10 border-b pb-10">
          <p className="mb-6 text-2xl">Browse by Categories</p>
          <KnowledgeBaseCategories
            knowledgeBaseCategories={topLevelCategories}
          />
        </div>
        <div className="mb-20">
          <p className="mb-6 text-2xl">Browse Resources</p>
          <div className="border-b">
            <FilterNavTabs
              navTabs={navTabItems}
              onTabClickCallBack={setTabSelectedCallBack}
              selectedTab={tabSelected}
            />
          </div>
          <div className="flex flex-col gap-4 pt-10">
            {tabSelected === 0 && (
              <>
                {latestContent?.length > 0 &&
                  latestContent.map((kbItem, index) => (
                    <KnowledgeBaseArticleLink
                      key={kbItem.id}
                      kbItem={kbItem}
                      showNewBadge
                      linkType="Recent Categories"
                      index={index}
                    />
                  ))}
                {!latestContent?.length && (
                  <p className="text-sm text-gray-400">
                    There is no new content.
                  </p>
                )}
              </>
            )}

            {tabSelected === 1 && (
              <>
                {recentArticles?.length > 0 &&
                  recentArticles.map((kbItem, index) => (
                    <KnowledgeBaseArticleLink
                      key={kbItem.id}
                      kbItem={kbItem}
                      linkType="Latest Articles"
                      index={index}
                    />
                  ))}
                {!recentArticles?.length && (
                  <p className="text-sm text-gray-400">
                    You have not visited any articles.
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default KnowledgeBaseHome
