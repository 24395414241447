import React from 'react'

import usePulse from './usePulse'

interface UseShapeOptions {
  type: string
  width: number
  height: number
  shapeColor: string
  strokeColor: string
  strokeWidth: string
  pulseStroke: boolean
}
// This component will render the SVG shape based on the props it receives
const ReactMapShapeFrame = ({
  type,
  width,
  height,
  shapeColor,
  strokeColor,
  strokeWidth,
  pulseStroke,
}: UseShapeOptions) => {
  // Use the custom hook to control the stroke opacity
  const strokeOpacity = usePulse(pulseStroke)

  const styles = {
    fill: shapeColor,
    strokeWidth: strokeWidth,
    stroke: strokeColor,
    strokeOpacity: pulseStroke ? strokeOpacity : 1,
  }

  const topRectHeight = height * 0.2 // Height of the top rectangle
  const bottomRectHeight = height * 0.8 // Height of the bottom rectangle
  const leftRectWidth = width * 0.2 // Width of the left rectangle

  switch (type) {
    case 'solidLine': {
      return (
        <path
          d={`M0,${height / 2} L${width},${height / 2}`}
          {...styles}
          fill="none"
          stroke={strokeColor}
          strokeWidth="2"
        />
      )
    }
    case 'dashedLine': {
      return (
        <path
          d={`M0,${height / 2} L${width},${height / 2}`}
          {...styles}
          fill="none"
          stroke={strokeColor}
          strokeWidth="2"
          strokeDasharray="4,4"
        />
      )
    }
    case 'dottedLine': {
      return (
        <path
          d={`M0,${height / 2} L${width},${height / 2}`}
          {...styles}
          fill="none"
          stroke={strokeColor}
          strokeWidth="2"
          strokeDasharray="1,3"
        />
      )
    }
    case 'zigzagLine': {
      return (
        <path
          d={`M0,${height / 2} L${width},${height / 2}`}
          {...styles}
          fill="none"
          stroke={strokeColor}
          strokeWidth="2"
          strokeDasharray="4,2,1,2" // Dash-Dot Pattern
        />
      )
    }
    case 'solidCornerLine': {
      return (
        <path
          d={`M0,0 L${width},0 M0,0 L0,${height}`}
          {...styles}
          fill="none"
          stroke={strokeColor}
          strokeWidth="2"
        />
      )
    }
    case 'dashedCornerLine': {
      return (
        <path
          d={`M0,0 L${width},0 M0,0 L0,${height}`}
          {...styles}
          fill="none"
          stroke={strokeColor}
          strokeWidth="2"
          strokeDasharray="4,4"
        />
      )
    }
    case 'dottedCornerLine': {
      return (
        <path
          d={`M0,0 L${width},0 M0,0 L0,${height}`}
          {...styles}
          fill="none"
          stroke="black"
          strokeWidth="2"
          strokeDasharray="1,3"
        />
      )
    }
    case 'zigzagCornerLine': {
      return (
        <g>
          <path
            d={`M0,0 L${width},0`} // Top horizontal line
            {...styles}
            fill="none"
            stroke="black"
            strokeWidth="2"
            strokeDasharray="4,2,1,2"
          />
          <path
            d={`M0,0 L0,${height}`} // Left vertical line
            {...styles}
            fill="none"
            stroke="black"
            strokeWidth="2"
            strokeDasharray="4,2,1,2"
          />
        </g>
      )
    }
    case 'circle':
      return (
        <ellipse
          cx={width / 2}
          cy={height / 2}
          rx={width / 2}
          ry={height / 2}
          {...styles}
        />
      )
    case 'round-rect':
      return (
        <rect x={0} y={0} rx={5} width={width} height={height} {...styles} />
      )
    case 'hexagon':
      return (
        <path
          d={`M10,0 L${width - 10},0  L${width},${height / 2} L${
            width - 10
          },${height} L10,${height} L0,${height / 2} z`}
          {...styles}
        />
      )
    case 'arrow':
      return (
        <path d={`M0,0 L0,${height} L${width},${height / 2} z`} {...styles} />
      )
    case 'arrow-rect':
      return (
        <path
          d={`M0,0 L${width - 10},0  L${width},${height / 2} L${
            width - 10
          },${height} L0,${height} z`}
          {...styles}
        />
      )
    case 'database':
      return (
        <path
          d={`M0,${height * 0.125}  L 0,${height - height * 0.125} A ${
            width / 2
          } ${height * 0.125} 0 1 0 ${width} ${
            height - height * 0.125
          } L ${width},${height * 0.125} A ${width / 2} ${
            height * 0.125
          } 0 1 1 0 ${height * 0.125} A ${width / 2} ${
            height * 0.125
          } 0 1 1 ${width} ${height * 0.125} A ${width / 2} ${
            height * 0.125
          } 0 1 1 0 ${height * 0.125} z`}
          {...styles}
          strokeWidth={styles.strokeWidth ? styles.strokeWidth : 1}
        />
      )
    case 'triangle':
      return (
        <path
          d={`M0,${height} L${width / 2},0 L${width},${height} z`}
          {...styles}
        />
      )
    case 'parallelogram':
      return (
        <path
          d={`M0,${height} L${width * 0.25},0 L${width},0 L${
            width - width * 0.25
          },${height} z`}
          {...styles}
        />
      )
    case 'up-swimlane':
      return (
        <>
          {/* Top Rectangle */}
          <rect x={0} y={0} width={width} height={topRectHeight} {...styles} />

          {/* Bottom Rectangle */}
          <rect
            x={0}
            y={height - bottomRectHeight}
            width={width}
            height={bottomRectHeight}
            {...styles}
          />
        </>
      )

    case 'left-swimlane':
      return (
        <>
          {/* Bottom Rectangle */}
          <rect
            x={0}
            y={height - bottomRectHeight}
            width={width}
            height={bottomRectHeight}
            {...styles}
          />

          {/* Left Rectangle */}
          <rect
            x={0}
            y={topRectHeight}
            width={leftRectWidth}
            height={bottomRectHeight}
            {...styles}
          />
        </>
      )

    case 'text':
      return <rect x={0} y={0} width={width} height={height} {...styles} />
    case 'terminator':
      return (
        <path
          d={`
    M ${width * 0.075},0
    h ${width * 0.85}
    c ${width * 0.1},0 ${width * 0.1},${height} 0,${height}
    h -${width * 0.85}
    c -${width * 0.1},0 -${width * 0.1},-${height} 0,-${height}
    Z
  `}
          {...styles}
        />
      )

    case 'process':
      return <rect x={0} y={0} width={width} height={height} {...styles} />
    case 'decision':
      return (
        <path
          d={`M${width / 2},0 L${width},${height / 2} L${
            width / 2
          },${height} L0,${height / 2} Z`}
          {...styles}
        />
      )
    case 'document':
      return (
        <path
          d={`M0,0 L${width},0 L${width},${height} A${width / 2},${
            width / 2
          } 0 0 0 ${width / 2},${height} A${width / 2},${
            width / 2
          } 0 0 1 0,${height} Z`}
          {...styles}
        />
      )
    case 'predefinedProcess':
      return (
        <path
          d={`M0,0 L${width},0 L${width},${height} L0,${height} Z M${
            width * 0.1
          },0 L${width * 0.1},${height} M${width * 0.9},0 L${
            width * 0.9
          },${height}`}
          {...styles}
        />
      )
    case 'data':
      return (
        <path
          d={`M${width * 0.2},0 L${width},${0} L${
            width * 0.8
          },${height} L0,${height} Z`}
          {...styles}
        />
      )
    case 'manualInput': // Assuming it is a trapezoid
      return (
        <path
          d={`M0,${height / 2} L${width},0 L${width},${height} L0,${height} Z`}
          {...styles}
        />
      )
    case 'preparation':
      return (
        <path
          d={`M${width * 0.2},0 L${width * 0.8},0 L${width},${height * 0.5} L${
            width * 0.8
          },${height} L${width * 0.2},${height} L0,${height * 0.5} Z`}
          {...styles}
        />
      )
    case 'manualOperation': // Assuming it is an octagon
      return (
        <path
          d={`M0,0 L${width},0 L${width - width / 6},${height} L${
            width / 6
          },${height} Z`}
          {...styles}
        />
      )
    case 'delay':
      return (
        <path
          d={`M0,${height} L0,0 L${(width * 1.5) / 3},0 A${width / 3},${
            width / 3
          } 0 0 1 ${(width * 1.5) / 3},${height} L0,${height} Z`}
          {...styles}
        />
      )
    case 'storage':
      return (
        <path
          d={`M0,${height * 0.125} L0,${height - height * 0.125} A ${
            width / 2
          } ${height * 0.125} 0 1 0 ${width} ${
            height - height * 0.125
          } L ${width},${height * 0.125} A ${width / 2} ${
            height * 0.125
          } 0 1 1 0 ${height * 0.125} A ${width / 2} ${
            height * 0.125
          } 0 1 1 ${width} ${height * 0.125} A ${width / 2} ${
            height * 0.125
          } 0 1 1 0 ${height * 0.125} z`}
          transform={`rotate(90 ${width / 2} ${height / 2})`}
          {...styles}
          strokeWidth={styles.strokeWidth ? styles.strokeWidth : 1}
        />
      )
    case 'merge':
      return (
        <path d={`M0,0 L${width / 2},${height} L${width},0 z`} {...styles} />
      )
    case 'or':
      return (
        <path
          d={`M${width / 2},0 A${width / 2},${height / 2} 0 1 0 ${
            width / 2
          },${height} A${width / 2},${height / 2} 0 1 0 ${width / 2},0 M0,${
            height / 2
          } L${width},${height / 2} M${width / 2},0 L${width / 2},${height}`}
          {...styles}
        />
      )
    case 'display': // Assuming it is a rounded rectangle
      return (
        <path
          d={`M${width * 0.2},0 L${width * 0.6},0 L${width},${height / 2} L${
            width * 0.6
          },${height} L${width * 0.2},${height} A${(width * 0.4) / 2},${
            height / 2
          } 0 1 1 ${width * 0.2},0 Z`}
          {...styles}
        />
      )
    case 'note':
      return (
        <path
          d={`M0,${height / 2} L${width / 2},${height / 2} M${width / 2},0 L${
            width / 2
          },${height} M${width / 2},0 L${width},0 M${
            width / 2
          },${height} L${width},${height}`}
          {...styles}
        />
      )
    default:
      return null
  }
}

export default ReactMapShapeFrame
