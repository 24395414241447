import { FC } from 'react'

import { Avatar, AvatarGroup } from '@mui/material'
import { PROD_CLIENT } from 'api/src/common/enums'
import dayjs from 'dayjs'

import { useAuth } from 'src/Providers'

export interface Author {
  id: number
  name?: string
  avatarUrl?: string
  role?: string
}

export interface AuthorsProps {
  updatedByUser?: Author
  updatedAt: string
  byText?: string
}
export enum AuthorsDateFormat {
  day = 'DD/MM/YYYY',
}

const AuthorsKnowledgebase: FC<AuthorsProps> = ({
  updatedByUser,
  updatedAt,
  byText = 'Authored by',
}) => {
  const { currentUser } = useAuth()

  const isStafflinkClient =
    currentUser?.parentData?.id === PROD_CLIENT.STAFFLINK

  const lastUpdatedIsStafflinkOrSuperAdmin =
    updatedByUser?.role === 'STAFFLINK' || updatedByUser?.role === 'SUPERADMIN'

  // get the current client data
  const currentClient = currentUser?.parentData

  // Determine who to display as the last updated by
  const lastUpdatedByName =
    (lastUpdatedIsStafflinkOrSuperAdmin || !updatedByUser) && !isStafflinkClient
      ? currentClient?.name + ' Team'
      : updatedByUser?.name

  const lastUpdatedByAvatarUrl =
    (lastUpdatedIsStafflinkOrSuperAdmin || !updatedByUser) && !isStafflinkClient
      ? currentClient?.logomarkUrl
      : updatedByUser?.avatarUrl

  return (
    <div
      className="flex space-x-1 font-normal text-sm p-0 py-3 items-start"
      data-testid="authors-cell"
    >
      <AvatarGroup>
        <Avatar
          title={`Last updated by ${lastUpdatedByName} on ${updatedAt}`}
          alt={lastUpdatedByName}
          src={lastUpdatedByAvatarUrl}
          className="h-6 w-6"
        />
      </AvatarGroup>
      <p className="text-gray-500 pt-[4px]" data-testid="authors-text">
        <span>{byText} </span>
        <span className="text-indigo-700"> {lastUpdatedByName}</span>
      </p>
    </div>
  )
}

export default AuthorsKnowledgebase
