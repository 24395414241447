import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { PlusIcon } from '@heroicons/react/24/solid'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { captureException } from '@sentry/browser'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import type {
  CreateReactMapCategoryMutation,
  CreateReactMapCategoryMutationVariables,
  CreateReactMapMutation,
  CreateReactMapMutationVariables,
  CreateReactMapsFromTemplatesMutation,
  CreateReactMapsFromTemplatesMutationVariables,
  DeleteReactMapCategoryMutation,
  DeleteReactMapCategoryMutationVariables,
  UpdateReactMapCategoryInput,
  UpdateReactMapCategoryMutation,
  UpdateReactMapCategoryMutationVariables,
} from 'types/graphql'
import { LearnerStatus } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { updateCache } from 'src/apolloCache/functions/updateCache'
import { reactMapCategoryConfig } from 'src/components/Library/RecordSettingsModal/config'
import RecordSettingsModal from 'src/components/Library/RecordSettingsModal/RecordSettingsModal'
import ReactMapCategoryModal from 'src/components/ReactMap/ReactMapCategoryModal'
import {
  ReactMapCategory,
  ReactMap,
  ReactMapsEditorRefetch,
  QUERY as FIND_REACT_MAP_CATEGORIES_QUERY,
} from 'src/components/ReactMap/ReactMapSidePanelCell/ReactMapSidePanelCell'
import ReactMapTemplatesModal from 'src/components/ReactMap/ReactMapTemplatesModal'
import useBoolean, { UseBooleanReturnType } from 'src/lib/hooks/UseBoolean'
import {
  CREATE_REACT_MAP,
  CREATE_REACT_MAP_CATEGORY,
  CREATE_REACT_MAPS_FROM_TEMPLATES,
  DELETE_REACT_MAP_CATEGORY,
  UPDATE_REACT_MAP_CATEGORY,
} from 'src/lib/queries/learner/ReactMaps/reactMapCategories'
import { useAuth } from 'src/Providers'
import { filterReactMaps } from 'src/Util'

import Button from '../Library/Button/Button'

import ReactMapSideItemList from './ReactMapSideItemList'

export const mapsEditRoles = ['ADMIN', 'EDITOR', 'OWNER']

export interface Option {
  id: number
  name: string
  __typename: string
  type: string
}

export interface ReactMapSidePanelProps {
  categoryList: ReactMapCategory[]
  uncategorisedMaps: ReactMap[]
  selectedMap?: number
  setSelectedMap?: Dispatch<SetStateAction<number>>
  refetch?: ReactMapsEditorRefetch
}

const ReactMapSidePanel: FC<ReactMapSidePanelProps> = ({
  categoryList,
  uncategorisedMaps,
  setSelectedMap,
  selectedMap,
  refetch,
}) => {
  const { hasRole } = useAuth()

  const isMember = hasRole(['MEMBER'])
  const [selectedCategory, setSelectedCategory] =
    useState<ReactMapCategory>(null)

  const [searchVal, setSearchVal] = useState<string>('')
  const [searchValue, setSearchValue] = useState<Option>(null)
  const createCategoryModal: UseBooleanReturnType = useBoolean(false)
  const recordModal: UseBooleanReturnType = useBoolean(false)
  const templateModal: UseBooleanReturnType = useBoolean(false)

  const filteredReactMapCategories: ReactMapCategory[] = filterReactMaps(
    categoryList,
    searchVal,
  )

  const [initialReactMaps, setInitialReactMaps] = useState<ReactMapCategory[]>(
    filteredReactMapCategories,
  )

  function mapSelected(id: number) {
    setSelectedMap(id)
    navigate(
      routes.reactMapById({
        id: id,
      }),
    )
  }

  const [createCategoryMutation] = useMutation<
    CreateReactMapCategoryMutation,
    CreateReactMapCategoryMutationVariables
  >(CREATE_REACT_MAP_CATEGORY, {
    update: (_, { data: { createReactMapCategory } }) =>
      updateCache({
        query: FIND_REACT_MAP_CATEGORIES_QUERY,
        mutationResult: createReactMapCategory,
        updatedField: 'mapCategoryList',
      }),
  })

  const createCategory = async (
    name: string,
  ): Promise<CreateReactMapCategoryMutation['createReactMapCategory']> => {
    const result = await createCategoryMutation({
      variables: {
        input: {
          name,
        },
      },
    })

    return result.data?.createReactMapCategory
  }

  const [createMapsFromTemplate] = useMutation<
    CreateReactMapsFromTemplatesMutation,
    CreateReactMapsFromTemplatesMutationVariables
  >(CREATE_REACT_MAPS_FROM_TEMPLATES, {
    onCompleted: async () => {
      await refetch()
      toast.success('Templates created successfully')
    },
  })

  const [createMapMutation] = useMutation<
    CreateReactMapMutation,
    CreateReactMapMutationVariables
  >(CREATE_REACT_MAP, {
    onCompleted: async () => {
      await refetch()
      toast.success('Map created')
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const newMap = async (categoryId: number) => {
    const response = await createMapMutation({
      variables: {
        input: {
          name: 'New Map',
          description: 'This is a blank starting map',
          diagramData: {},
          isVisible: true,
          legend: JSON.stringify([]),
          reactMapCategoryId: categoryId,
          connectors: JSON.stringify([]),
          nodes: JSON.stringify([]),
        },
      },
    })
    const newMapId = response?.data?.createReactMap?.id
    if (newMapId) {
      mapSelected(newMapId)
    }
    return response
  }

  const [updateCategoryMutation] = useMutation<
    UpdateReactMapCategoryMutation,
    UpdateReactMapCategoryMutationVariables
  >(UPDATE_REACT_MAP_CATEGORY, {
    onCompleted: async (data) => {
      await refetch()
      setSelectedCategory(data.updateReactMapCategory as ReactMapCategory)
      toast.success('Category updated')
    },
    onError: (error) => toast.error(error.message),
  })

  const updateCategory = async (
    id: number,
    input: UpdateReactMapCategoryInput,
  ): Promise<UpdateReactMapCategoryMutation['updateReactMapCategory']> => {
    const result = await updateCategoryMutation({
      variables: { id, input: input },
    })
    return result.data?.updateReactMapCategory
  }

  const [deleteCategoryMutation] = useMutation<
    DeleteReactMapCategoryMutation,
    DeleteReactMapCategoryMutationVariables
  >(DELETE_REACT_MAP_CATEGORY, {
    update: (_, { data: { deleteReactMapCategory } }) =>
      updateCache({
        query: FIND_REACT_MAP_CATEGORIES_QUERY,
        mutationResult: deleteReactMapCategory,
        updatedField: 'mapCategoryList',
      }),
    onCompleted: () => {
      toast.success('Category deleted')
    },
    onError: (error) => toast.error(error.message),
  })

  const deleteCategory = (id: number) => {
    return deleteCategoryMutation({ variables: { id } })
  }

  const togglePublished = (category: ReactMapCategory) => {
    const id = category.id
    const input = {
      status: (category.status === 'PUBLISHED'
        ? 'DRAFT'
        : 'PUBLISHED') as LearnerStatus,
    }
    return updateCategory(id, input)
  }

  const handleModalToggle = (modalType) => {
    switch (modalType) {
      case 'new':
        createCategoryModal.toggle()
        setSelectedCategory(null)
        break
      case 'category':
        recordModal.toggle()
        break
      case 'template':
        templateModal.toggle()
        break
      default:
        break
    }
  }

  const getOptions = (): Option[] => {
    const options: Option[] = []
    for (const mapCategory of filteredReactMapCategories) {
      options.push({
        id: mapCategory.id,
        name: mapCategory.name,
        __typename: mapCategory.__typename,
        type: 'Category',
      })
      for (const map of mapCategory.reactMaps) {
        options.push({
          id: map.id,
          name: map.name,
          __typename: map.__typename,
          type: 'Map',
        })
      }
    }
    return options.sort((a, b) => -b.name.localeCompare(a.name))
  }

  const handleCreateFrom = (id) => {
    handleModalToggle('template')
    setSelectedCategory(filteredReactMapCategories.find((cat) => cat.id === id))
  }

  useEffect(() => {
    if (searchValue?.__typename) {
      if (searchValue.__typename === 'ReactMapCategory') {
        setSelectedMap(searchValue.id)
      } else {
        mapSelected(searchValue.id)
      }
    }
  }, [searchValue])

  useEffect(() => {
    const filteredReactMapCategories: ReactMapCategory[] = filterReactMaps(
      categoryList,
      searchVal,
    )

    setInitialReactMaps(filteredReactMapCategories)
  }, [categoryList, searchVal])

  return (
    <div className="flex h-full w-full align-middle">
      <aside
        className={
          'w-full flex-shrink-0 overflow-y-scroll border-r bg-white transition-all duration-100'
        }
      >
        <div className="flex h-full flex-col bg-white">
          <div className="sticky top-0 z-10 border-b bg-white p-4">
            <Autocomplete
              options={getOptions()}
              id="autocomplete-search"
              data-testid="mapper-search-bar"
              size="small"
              disableClearable
              fullWidth={true}
              freeSolo
              autoHighlight
              groupBy={(option) => option?.type}
              getOptionLabel={(option: Option) => option?.name ?? ''}
              renderOption={(props, option, { inputValue }) => {
                const matches = match(option.name, inputValue)
                const parts = parse(option.name, matches)
                return (
                  <li {...props} key={option.name + option.id}>
                    <div>
                      {parts.map((part, index) => (
                        <span
                          key={index}
                          style={{
                            fontWeight: part.highlight ? 700 : 400,
                          }}
                        >
                          {part.text}
                        </span>
                      ))}
                    </div>
                  </li>
                )
              }}
              onChange={(
                _event: React.ChangeEvent<HTMLInputElement>,
                newValue: Option | null,
              ) => {
                setSearchValue(newValue)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Maps & Categories"
                  className="bg-white"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchVal(event.target.value)
                  }}
                />
              )}
            />
          </div>
          <div className="relative flex h-full flex-col justify-between gap-2">
            <ReactMapSideItemList
              uncategorisedMaps={uncategorisedMaps}
              categories={initialReactMaps}
              setSelectedMap={mapSelected}
              selectedMap={selectedMap}
              setSelectedCategory={setSelectedCategory}
              selectedCategory={selectedCategory}
              handleModalToggle={handleModalToggle}
              createItem={(categoryId) => newMap(categoryId)}
              handleCreateFrom={handleCreateFrom}
            />

            {!isMember && (
              <div className="sticky bottom-0 w-full border-t bg-white p-4">
                <Button
                  fullWidth={true}
                  startIcon={<PlusIcon className="h-5 w-5" />}
                  className="min-w-[0] rounded-lg bg-indigo-500 text-white hover:bg-indigo-700"
                  onClick={() => handleModalToggle('new')}
                  buttonDataTestId="drag-drop-card-add-category-button"
                >
                  Create Category
                </Button>
              </div>
            )}
          </div>
        </div>
      </aside>

      {recordModal.value && selectedCategory && (
        <RecordSettingsModal
          modalVisible={recordModal}
          record={{
            id: selectedCategory.id,
            type: 'ReactMapCategory',
            name: selectedCategory.name,
            description: selectedCategory.description,
            status: selectedCategory.status,
          }}
          config={reactMapCategoryConfig}
          onDelete={async () => {
            try {
              await deleteCategory(selectedCategory.id)
            } catch (error) {
              if (
                error.message.includes(
                  'cannot be deleted because it has Process Maps associated with it',
                )
              ) {
                toast.error(
                  'Category cannot be deleted because it has Process Maps associated with it',
                  {
                    duration: 5000,
                  },
                )
              } else {
                toast.error('Error deleting category')
              }
              captureException(error)
            }
          }}
          onSave={async (record) => {
            await updateCategory(record.id, {
              name: record.name,
              status: record.status === 'DRAFT' ? 'DRAFT' : 'PUBLISHED',
            })
          }}
        />
      )}
      {createCategoryModal.value && (
        <ReactMapCategoryModal
          {...{
            createCategoryModal,
            selectedCategory,
            clearSelectedCategory: () => setSelectedCategory(null),
            togglePublished,
            createCategory,
          }}
        />
      )}
      <ReactMapTemplatesModal
        modalVisible={templateModal}
        createMapsFromTemplate={createMapsFromTemplate}
        selectedCategory={selectedCategory}
      />
    </div>
  )
}

export default ReactMapSidePanel
