import React, { Dispatch, FC, SetStateAction } from 'react'

import { GlobeAltIcon } from '@heroicons/react/24/outline'
import { Tooltip } from '@mui/material'

import { useAuth } from 'src/Providers'
import { published } from 'src/Util'

import { mapsEditRoles } from './ReactMapSidePanel'
import { ReactMap } from './ReactMapSidePanelCell'

interface ReactMapSideItemProps {
  item: ReactMap
  selectedItem: number
  setSelectedItem: Dispatch<SetStateAction<number>>
  DragHandle: React.ReactNode
}

const ReactMapSideItem: FC<ReactMapSideItemProps> = ({
  item,
  selectedItem,
  setSelectedItem,
  DragHandle,
}) => {
  const { hasRole } = useAuth()

  const isPublished = published(item)

  return (
    <div
      tabIndex={0}
      onKeyDown={() => {}}
      onClick={() => {
        setSelectedItem(item.id)
      }}
      role="button"
      className={`p-0 pr-2 bg-white py-2 text-left flex items-center gap-2 !border cursor-pointer ${
        selectedItem === item.id
          ? 'hover:bg-indigo-100 !bg-indigo-50  !border-indigo-600'
          : 'hover:bg-gray-100 bg-gray-50 !border-gray-50 !border-b-gray-200'
      }`}
    >
      {hasRole(mapsEditRoles) ? DragHandle : <span className="w-[26px]"></span>}
      <span className="inline-flex wrap line-clamp-1 grow py-1">
        <div
          data-testid={`drag-drop-${item.name}`}
          className={' text-sm line-clamp-1 grow text-gray-600'}
        >
          {item.name}
        </div>
      </span>
      {hasRole(mapsEditRoles) && (
        <div className="flex flex-row justify-end items-center gap-1">
          {item.isTemplate && (
            <Tooltip title={'Global Template'}>
              <GlobeAltIcon className={'text-blue-400 h-4 w-4'} />
            </Tooltip>
          )}
          {isPublished && (
            <span className="text-xs text-emerald-600 bg-emerald-100 rounded-full px-2 py-0.5">
              Published
            </span>
          )}
          {!isPublished && (
            <span className="text-xs text-amber-600 bg-amber-100 rounded-full px-2 py-0.5">
              Draft
            </span>
          )}
        </div>
      )}
    </div>
  )
}

export default ReactMapSideItem
