import * as React from 'react'

import { ShareIcon } from '@heroicons/react/24/outline'
import { Node, NodePositionChange, XYPosition } from 'reactflow'

import { ReactMap } from 'src/components/ReactMap/ReactMapSidePanelCell/ReactMapSidePanelCell'

import { lineOptionsType } from './ReactMapControlPanelLineProperties'

export type GetHelperLinesResult = {
  horizontal?: number
  vertical?: number
  snapPosition: Partial<XYPosition>
}

export type IsEqualCompareObj = {
  minWidth: number
  minHeight: number
  hasChildNodes: boolean
}

// we have to make sure that parent nodes are rendered before their children
export const sortNodes = (a: Node, b: Node): number => {
  if (a.type === b.type) {
    return 0
  }
  return a.type === 'ReactMapGroupNode' && b.type !== 'ReactMapGroupNode'
    ? -1
    : 1
}

export const strokeTypes: lineOptionsType[] = [
  { label: 'Straight', value: 'straight' },
  { label: 'Step', value: 'step' },
  { label: 'Smooth', value: 'smoothstep' },
  { label: 'Bezier', value: 'simplebezier' },
]

export const endTypes: lineOptionsType[] = [
  { label: 'None', value: 'none' },
  { label: 'Arrow', value: 'arrow' },
  { label: 'Closed Arrow', value: 'arrowclosed' },
]

export const thicknessTypes: lineOptionsType[] = [
  { label: '0.25', value: 0.25 },
  { label: '0.50', value: 0.5 },
  { label: '1.00', value: 1 },
  { label: '2.00', value: 2 },
  { label: '5.00', value: 5 },
]

export const legendShapes = [
  'text',
  'terminator',
  'process',
  'decision',
  'document',
  'predefinedProcess',
  'data',
  'manualInput',
  'preparation',
  'database',
  'manualOperation',
  'delay',
  'storage',
  'merge',
  'or',
  'display',
  'note',
  'round-rect',
  'circle',
  'arrow',
  'hexagon',
  'triangle',
  'parallelogram',
  'arrow-rect',
]
export const lineShapes = [
  'solidLine',
  'dashedLine',
  'dottedLine',
  'zigzagLine',
  'solidCornerLine',
  'dashedCornerLine',
  'dottedCornerLine',
  'zigzagCornerLine',
]

export const swimLanes = ['up-swimlane', 'left-swimlane']

export const getId = (prefix = 'ReactMapCustomNode') =>
  `${prefix}_${Math.random() * 10000}`

export const getNodePositionInsideParent = (
  node: Partial<Node>,
  groupNode: Node,
) => {
  const position = node.position ?? { x: 0, y: 0 }
  const nodeWidth = node.width ?? 0
  const nodeHeight = node.height ?? 0
  const groupWidth = groupNode.width ?? 0
  const groupHeight = groupNode.height ?? 0

  if (position.x < groupNode.position.x) {
    position.x = 0
  } else if (position.x + nodeWidth > groupNode.position.x + groupWidth) {
    position.x = groupWidth - nodeWidth
  } else {
    position.x = position.x - groupNode.position.x
  }

  if (position.y < groupNode.position.y) {
    position.y = 0
  } else if (position.y + nodeHeight > groupNode.position.y + groupHeight) {
    position.y = groupHeight - nodeHeight
  } else {
    position.y = position.y - groupNode.position.y
  }

  return position
}

export const getHelperLines = (
  change: NodePositionChange,
  nodes: Node[],
  distance: number = 5,
): GetHelperLinesResult => {
  const defaultResult = {
    horizontal: undefined,
    vertical: undefined,
    snapPosition: { x: undefined, y: undefined },
  }
  const nodeA = nodes.find((node: Node) => node.id === change.id)

  if (!nodeA || !change.position) {
    return defaultResult
  }

  const nodeABounds = {
    left: change.position.x,
    right: change.position.x + (nodeA.width ?? 0),
    top: change.position.y,
    bottom: change.position.y + (nodeA.height ?? 0),
    width: nodeA.width ?? 0,
    height: nodeA.height ?? 0,
  }

  let horizontalDistance = distance
  let verticalDistance = distance

  const alignmentResults = nodes
    .filter((node) => node.id !== nodeA.id)
    .reduce<GetHelperLinesResult>((result, nodeB) => {
      const nodeBBounds = {
        left: nodeB.position.x,
        right: nodeB.position.x + (nodeB.width ?? 0),
        top: nodeB.position.y,
        bottom: nodeB.position.y + (nodeB.height ?? 0),
        width: nodeB.width ?? 0,
        height: nodeB.height ?? 0,
      }

      const distanceLeftLeft = Math.abs(nodeABounds.left - nodeBBounds.left)

      if (distanceLeftLeft < verticalDistance) {
        result.snapPosition.x = nodeBBounds.left
        result.vertical = nodeBBounds.left
        verticalDistance = distanceLeftLeft
      }

      const distanceRightRight = Math.abs(nodeABounds.right - nodeBBounds.right)

      if (distanceRightRight < verticalDistance) {
        result.snapPosition.x = nodeBBounds.right - nodeABounds.width
        result.vertical = nodeBBounds.right
        verticalDistance = distanceRightRight
      }

      const distanceLeftRight = Math.abs(nodeABounds.left - nodeBBounds.right)

      if (distanceLeftRight < verticalDistance) {
        result.snapPosition.x = nodeBBounds.right
        result.vertical = nodeBBounds.right
        verticalDistance = distanceLeftRight
      }

      const distanceRightLeft = Math.abs(nodeABounds.right - nodeBBounds.left)

      if (distanceRightLeft < verticalDistance) {
        result.snapPosition.x = nodeBBounds.left - nodeABounds.width
        result.vertical = nodeBBounds.left
        verticalDistance = distanceRightLeft
      }

      const distanceTopTop = Math.abs(nodeABounds.top - nodeBBounds.top)

      if (distanceTopTop < horizontalDistance) {
        result.snapPosition.y = nodeBBounds.top
        result.horizontal = nodeBBounds.top
        horizontalDistance = distanceTopTop
      }

      const distanceBottomTop = Math.abs(nodeABounds.bottom - nodeBBounds.top)

      if (distanceBottomTop < horizontalDistance) {
        result.snapPosition.y = nodeBBounds.top - nodeABounds.height
        result.horizontal = nodeBBounds.top
        horizontalDistance = distanceBottomTop
      }

      const distanceBottomBottom = Math.abs(
        nodeABounds.bottom - nodeBBounds.bottom,
      )

      if (distanceBottomBottom < horizontalDistance) {
        result.snapPosition.y = nodeBBounds.bottom - nodeABounds.height
        result.horizontal = nodeBBounds.bottom
        horizontalDistance = distanceBottomBottom
      }

      const distanceTopBottom = Math.abs(nodeABounds.top - nodeBBounds.bottom)

      if (distanceTopBottom < horizontalDistance) {
        result.snapPosition.y = nodeBBounds.bottom
        result.horizontal = nodeBBounds.bottom
        horizontalDistance = distanceTopBottom
      }

      return result
    }, defaultResult)

  return alignmentResults
}

export const defaultEdgeOptions = {
  type: 'smoothstep',
  style: { strokeWidth: 2, stroke: '#000000' },
}

export const getUncategorisedMaps = (mapList: ReactMap[]) => {
  const uncategorisedMaps = mapList
    .map((mapListItem) => {
      if (!mapListItem.reactMapCategoryId) {
        return mapListItem
      }
      return null
    })
    .filter(Boolean)

  return uncategorisedMaps
}

export const MapperEditorCellEmpty = (
  <div className="flex h-[calc(100vh-64px)]">
    <div className="grow p-1 grid place-items-center border-2 border-gray-300 rounded-xl border-dashed m-16">
      <div className="text-center">
        <ShareIcon className="text-gray-400 w-8 h-8 m-auto" />
        <span className="mt-2 block text-sm font-medium text-gray-900">
          Select a map
        </span>
      </div>
    </div>
  </div>
)

export const isEqual = (
  prev: IsEqualCompareObj,
  next: IsEqualCompareObj,
): boolean => {
  return (
    prev.minWidth === next.minWidth &&
    prev.minHeight === next.minHeight &&
    prev.hasChildNodes === next.hasChildNodes
  )
}
