import { FC } from 'react'

import type {
  GetAllKnowledgeBaseItemsQuery,
  GetAllKnowledgeBaseItemsQueryVariables,
} from 'types/graphql'
import { useMediaQuery } from 'usehooks-ts'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'

import KnowledgeBase from '../KnowledgeBase/KnowledgeBase'

export enum KB_ITEM_TYPE {
  ARTICLE = 'ARTICLE',
  DOCUMENT = 'DOCUMENT',
  URL = 'URL',
  CATEGORY = 'CATEGORY',
  STORAGE_OBJECT = 'STORAGE_OBJECT',
}

export enum KnowledgeBaseItemStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

export const QUERY = gql`
  query GetAllKnowledgeBaseItemsQuery(
    $statuses: [KnowledgeBaseItemStatus!]
    $asAdmin: Boolean = false
  ) {
    kbItems: knowledgeBaseItems(statuses: $statuses, asAdmin: $asAdmin) {
      id
      title
      url
      type
      createdBy
      createdAt
      updatedBy
      updatedAt
      parentId
      isGlobal
      status
    }
  }
`
export type BeforeQueryProps = {
  statuses: KnowledgeBaseItemStatus[]
  asAdmin: boolean
}

export const beforeQuery = ({ statuses, asAdmin }: BeforeQueryProps) => {
  return {
    variables: { statuses, asAdmin },
    fetchPolicy: 'cache-and-network',
  }
}

export const Loading = () => (
  <div
    className="flex items-center justify-center"
    style={{ minHeight: 'calc(100vh - 80px)' }}
  >
    <LoadingSpinner />
  </div>
)

export const Empty: FC<Props> = ({ kbItems, id, asAdmin, statuses }) => {
  return (
    <KnowledgeBase
      id={Number(id)}
      kbItems={kbItems}
      statuses={statuses as KnowledgeBaseItemStatus[]}
      canEdit={asAdmin}
    />
  )
}

export const Failure = ({ error }: CellFailureProps) => (
  <div className="rw-cell-error">{error.message}</div>
)

export type Props = CellSuccessProps<
  GetAllKnowledgeBaseItemsQuery,
  GetAllKnowledgeBaseItemsQueryVariables
> & {
  id: number
  asAdmin: boolean
} & GetAllKnowledgeBaseItemsQueryVariables

export const Success: FC<Props> = ({ kbItems, id, asAdmin, statuses }) => {
  const isTablet = useMediaQuery('(max-width: 1023px)')

  if (!asAdmin) {
    kbItems = kbItems.filter((kbItem) => kbItem.status === 'PUBLISHED')
  }

  // hide actions and labels if edit permissions are not available or on tablet
  const canEdit = asAdmin && !isTablet

  return (
    <KnowledgeBase
      id={Number(id)}
      kbItems={kbItems}
      statuses={statuses as KnowledgeBaseItemStatus[]}
      canEdit={canEdit}
    />
  )
}
