import { useCallback } from 'react'

import { useReactFlow, useStoreApi } from 'reactflow'

export type DetachNodesFunction = (
  ids: string[],
  removeParentId?: string | null,
) => void

const useDetachNodes = (): DetachNodesFunction => {
  const { setNodes } = useReactFlow()
  const store = useStoreApi()

  return useCallback(
    (ids: string[], removeParentId: string = null) => {
      const { nodeInternals } = store.getState()
      const nextNodes: Record<string, any> = Array.from(
        nodeInternals.values(),
      ).map((n: Record<string, any>) => {
        if (ids.includes(n.id) && n.parentNode) {
          const parentNode: Record<string, any> = nodeInternals.get(
            n.parentNode,
          )
          return {
            ...n,
            position: {
              x: n.position.x + (parentNode?.positionAbsolute?.x ?? 0),
              y: n.position.y + (parentNode?.positionAbsolute?.y ?? 0),
            },
            extent: undefined,
            parentNode: undefined,
          }
        }
        return n
      })
      setNodes(
        nextNodes.filter(
          (n) =>
            !removeParentId ||
            n.id !== removeParentId ||
            n.type === 'ReactMapGroupNode',
        ),
      )
    },
    [setNodes, store],
  )
}

export default useDetachNodes
