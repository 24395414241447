import React, { FC, useEffect, useState } from 'react'

import { TextField } from '@mui/material'
import { CreateReactMapCategoryMutation, TableName } from 'types/graphql'

import { toast } from '@redwoodjs/web/toast'

import Modal from 'src/components/Modal'
import { ReactMapCategory } from 'src/components/ReactMap/ReactMapSidePanelCell/ReactMapSidePanelCell'
import useACL from 'src/lib/hooks/ACL/useACL'
import { UseBooleanReturnType } from 'src/lib/hooks/UseBoolean'

export interface ReactMapCategoryModalProps {
  createCategoryModal: UseBooleanReturnType
  selectedCategory:
    | ReactMapCategory
    | CreateReactMapCategoryMutation['createReactMapCategory']
  clearSelectedCategory: () => void
  createCategory: (
    name: string,
  ) => Promise<CreateReactMapCategoryMutation['createReactMapCategory']>
}

const ReactMapCategoryModal: FC<ReactMapCategoryModalProps> = ({
  createCategoryModal,
  selectedCategory,
  clearSelectedCategory,
  createCategory,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [newCategoryName, setNewCategoryName] = useState<string>('')
  const [newCategoryNameError, setNewCategoryNameError] =
    useState<boolean>(false)

  const { AccessControlList, saveACL } = useACL({
    resourceType: 'ReactMapCategory' as TableName,
    resourceId: selectedCategory?.id,
    principalTypes: ['MEMBERSHIP', 'MEMBERSHIPGROUP'],
  })

  const handleSubmit = async () => {
    setIsLoading(true)

    let inputIsValid = false

    if (newCategoryName?.length > 0) {
      inputIsValid = true
    }
    if (inputIsValid) {
      try {
        const category = await createCategory(newCategoryName)
        await saveACL({ resourceId: category.id })
        toast.success('Category created')

        handleClose()
      } catch (error) {
        toast.error('Error creating category')
        handleClose()
      }
    } else {
      setNewCategoryNameError(true)
    }
    setIsLoading(false)
  }

  const handleClose = () => {
    setNewCategoryName('')
    setNewCategoryNameError(false)
    setIsLoading(false)
    clearSelectedCategory()
    createCategoryModal.setFalse()
  }

  // Clean up error on change
  useEffect(() => {
    if (newCategoryName) {
      setNewCategoryNameError(false)
    } else {
      setNewCategoryNameError(true)
    }
  }, [newCategoryName])

  return (
    <Modal
      open={true}
      onClose={handleClose}
      title="Create Process Map Category"
      dialogClassName="max-w-xl"
      footerVisible
      confirmText="Create Category"
      onConfirm={handleSubmit}
      onCancel={handleClose}
      loading={isLoading}
    >
      <div className={'flex flex-col w-full items-center justify-center p-8'}>
        <form className="w-full flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <label
              htmlFor="#new-map-category"
              className="block text-gray-500 pb-1"
            >
              Category Name
            </label>
            <TextField
              className="w-full"
              name="categoryName"
              id="new-map-category"
              variant="outlined"
              size="small"
              required
              value={newCategoryName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setNewCategoryName(event.target.value)
              }}
              error={newCategoryNameError}
              helperText={
                newCategoryNameError ? 'Category name cannot be empty' : ''
              }
            />
          </div>
          <AccessControlList />
        </form>
      </div>
    </Modal>
  )
}

export default ReactMapCategoryModal
