import create from 'zustand'

const useReactFlowStore = create((set, get) => ({
  history: {
    nodes: [],
    edges: [],
    undo: [],
    redo: [],
  },
  setHistory: (history) => set({ history }),

  searchText: '',
  setSearchText: (value) => set({ searchText: value }),

  isEditing: false,
  setIsEditing: (value) => set({ isEditing: value }),

  nodes: [],
  setNodes: (value) => set({ nodes: value }),
  handleSetNodeText: (newText, nodeId = null) => {
    // Implementation of setting node text
    // This will use the get() function to access the current state
    const nodes = get().nodes
    const selectedNodeId = nodeId
      ? nodeId
      : nodes.find((node) => node.selected === true)?.id
    if (selectedNodeId) {
      const updatedNodes = nodes.map((node) => {
        if (node.id === selectedNodeId) {
          const newData = { ...node.data, label: newText }
          return { ...node, data: newData }
        }
        return node
      })
      get().setNodes(updatedNodes) // Update the nodes in the state
    }
  },
}))

export default useReactFlowStore
