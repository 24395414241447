import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useLazyQuery } from '@apollo/client'
import type { OutputData } from '@editorjs/editorjs'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import { TextField } from '@mui/material'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import {
  GetKnowledgeBaseItemQuery,
  GetKnowledgeBaseItemQueryVariables,
} from 'types/graphql'
import { useLocalStorage } from 'usehooks-ts'

import AuthorsKnowledgebase from 'src/components/AuthorsKnowledgebase/AuthorsKnowledgebase'
import { LinkedResourceType } from 'src/components/HubDash/HubDashLayoutListCell'
import { KB_ITEM_TYPE } from 'src/components/KnowledgeCell'
import { KBItemSorted } from 'src/components/KnowledgeCell/types'
import DocumentEditor from 'src/components/Library/DocumentEditor/DocumentEditor'
import LinkedContentBadge, {
  type LinkedResourceRow,
} from 'src/components/Library/LinkedContentBadge/LinkedContentBadge'
import RichTextEditor from 'src/components/Library/RichTextEditor/RichTextEditor'
import { openWindowWithBlockCheck } from 'src/lib/helpers'
import useDebounce from 'src/lib/hooks/UseDebounce'
import { useAuth } from 'src/Providers'

import { contentListLimit } from '../Home/KnowledgeBaseHome'

import KnowledgeBaseArticleSkeleton from './KnowledgeBaseArticleSkeleton'

export const KB_ITEM_QUERY = gql`
  query GetKnowledgeBaseItemQuery($id: Int!) {
    kbItem: knowledgeBaseItem(id: $id) {
      id
      title
      url
      type
      text
      document
      createdBy
      createdAt
      updatedBy
      updatedAt
      parentId
      status
      isGlobal
      updatedByUser {
        id
        name
        avatarUrl
        role
      }
    }
  }
`

export type KbItemDetails = GetKnowledgeBaseItemQuery['kbItem']

interface KnowledgeBaseArticleProps {
  selectedKbItem: KBItemSorted
  kbItems: KBItemSorted[]
  canEdit: boolean
  userCanEditGlobal: boolean
  isEditing: boolean
  onSaveClick: (args: any) => void
  saveDisabled: boolean
  setSaveDisabled: Dispatch<SetStateAction<boolean>>
  isSaving: boolean
  setHasChanges: Dispatch<SetStateAction<boolean>>
  setLinkedWorkflowsRows: Dispatch<SetStateAction<LinkedResourceRow[]>>
}

const KnowledgeBaseArticle: FC<KnowledgeBaseArticleProps> = ({
  selectedKbItem,
  kbItems,
  canEdit,
  userCanEditGlobal,
  isEditing,
  onSaveClick,
  saveDisabled,
  setSaveDisabled,
  isSaving,
  setHasChanges,
  setLinkedWorkflowsRows,
}) => {
  const { currentUser } = useAuth()

  const [recentArticles, setRecentArticles] = useLocalStorage(
    `memberId-${currentUser.membershipData?.id}-knowledgeBase-recentArticles`,
    [],
  )
  const parentItem = useMemo(() => {
    const parent = kbItems.find((item) => item.id === selectedKbItem?.parentId)
    return parent
  }, [kbItems, selectedKbItem])

  const [articleData, setArticleData] = useState<KbItemDetails>(null)

  const [fetchError, setFetchError] = useState<string>(null)
  const [updatedMinutes, setUpdatedMinutes] = useState<number>(null)
  const [initialLoad, setInitialLoad] = useState<boolean>(true)

  const [articleTitle, setArticleTitle] = useState<string>(
    selectedKbItem?.title,
  )
  const [articleUrl, setArticleUrl] = useState<string>(selectedKbItem?.url)
  const [articleText, setArticleText] = useState<string>('')
  const [articleDocumentData, setArticleDocumentData] = useState(null)

  const debounceDelay = 800
  const debouncedTitle = useDebounce(articleTitle, debounceDelay)
  const debouncedArticleText = useDebounce(articleText, debounceDelay)
  const debouncedArticleDocumentData = useDebounce(
    articleDocumentData,
    debounceDelay,
  )
  const debouncedLinkUrl = useDebounce(articleUrl, debounceDelay)

  useEffect(() => {
    // Updated the local state for visited article IDs
    if (recentArticles) {
      if (!recentArticles.includes(selectedKbItem.id)) {
        if (recentArticles.length === contentListLimit) {
          recentArticles.pop()
        }
        setRecentArticles([selectedKbItem.id, ...recentArticles])
      }
    }
    setFetchError(null)

    if (selectedKbItem?.id) {
      setInitialLoad(true) // Start initial load

      const getArticleData = async () => {
        const { data } = await getKbArticleData({
          variables: {
            id: selectedKbItem?.id,
          },
        })
        return data
      }

      setArticleData(null)
      setArticleTitle(null)
      setArticleText(null)
      setArticleDocumentData(null)
      setArticleUrl(null)

      getArticleData().then((data) => {
        setArticleData(data?.kbItem)
        setArticleTitle(data?.kbItem?.title)
        setArticleText(data?.kbItem?.text)
        setArticleDocumentData(data?.kbItem?.document as any)
        setArticleUrl(data?.kbItem?.url)
        setInitialLoad(false) // End initial load
      })
    } else {
      setArticleData(null)
      setArticleTitle(null)
      setArticleText(null)
      setArticleDocumentData(null)
      setArticleUrl(null)
    }
  }, [selectedKbItem.id])

  // Detect changes to title, text, document, or url in real-time
  useEffect(() => {
    if (!articleData) return // Ensure articleData is available

    const titleChanged = articleTitle !== articleData.title
    const textChanged = articleText !== articleData.text
    const documentDataChanged =
      JSON.stringify(articleDocumentData) !==
      JSON.stringify(articleData.document)
    const linkUrlChanged = articleUrl !== articleData.url

    // Set hasChanges to true if any of the fields have changed
    if (titleChanged || textChanged || documentDataChanged || linkUrlChanged) {
      setHasChanges(true)
    } else {
      setHasChanges(false)
    }
  }, [
    articleTitle,
    articleText,
    articleDocumentData,
    articleUrl,
    articleData,
    setHasChanges,
  ])

  // Reset hasChanges when isSaving becomes false
  useEffect(() => {
    if (!isSaving) {
      setHasChanges(false)
    }
  }, [isSaving, setHasChanges])

  // Title save useEffect
  useEffect(() => {
    if (initialLoad) return // don't save on initial load
    if (!articleData) return // ensure articleData is available

    if (isEditing && !saveDisabled && debouncedTitle !== articleData.title) {
      handleTitleSave()
    }
  }, [debouncedTitle])

  // Auto-save useEffect
  useEffect(() => {
    if (initialLoad) return // don't save on initial load
    if (!articleData) return // ensure articleData is available

    const documentDataChanged =
      JSON.stringify(debouncedArticleDocumentData) !==
      JSON.stringify(articleData.document)
    const articleTextChanged = debouncedArticleText !== articleData.text
    const linkUrlChanged = debouncedLinkUrl !== articleData.url

    if (
      isEditing &&
      !saveDisabled &&
      (documentDataChanged || articleTextChanged || linkUrlChanged)
    ) {
      handleAutoSave()
    }
  }, [debouncedArticleDocumentData, debouncedArticleText, debouncedLinkUrl])

  const handleTitleSave = async () => {
    await onSaveClick({
      id: selectedKbItem.id,
      title: debouncedTitle,
      url: debouncedLinkUrl,
      text: debouncedArticleText,
      document: debouncedArticleDocumentData,
      parent: parentItem,
      isEditing: false,
      optimisticallyUpdate: false,
    })
  }

  const handleAutoSave = async () => {
    await onSaveClick({
      id: selectedKbItem.id,
      title: debouncedTitle,
      url: debouncedLinkUrl,
      text: debouncedArticleText,
      document: debouncedArticleDocumentData,
      parent: parentItem,
      isEditing: true,
      optimisticallyUpdate: true,
    })
  }

  const [getKbArticleData, { loading }] = useLazyQuery<
    GetKnowledgeBaseItemQuery,
    GetKnowledgeBaseItemQueryVariables
  >(KB_ITEM_QUERY, {
    onCompleted: (data) => {
      return data
    },
    onError: (error) => {
      setFetchError(error.message)

      return null
    },
  })

  useEffect(() => {
    // Link Type = go out
    if (articleData?.type === KB_ITEM_TYPE.URL) {
      openWindowWithBlockCheck(articleData.url, '_blank')
    }

    if (articleData?.updatedAt) {
      const now = dayjs()
      const updatedTimeStamp = dayjs(new Date(articleData?.updatedAt))

      const minuteDifference = updatedTimeStamp.diff(now, 'minutes')

      setUpdatedMinutes(minuteDifference)
    } else {
      setUpdatedMinutes(null)
    }
  }, [articleData])

  // if the user can edit global and the article is global, they can edit
  // else they can edit and is editing then editing mode is true, else false
  const [readerMode, setReaderMode] = useState(false)
  useEffect(() => {
    // Only evaluate after selectedKbItem is defined
    if (selectedKbItem !== undefined) {
      if (userCanEditGlobal && selectedKbItem.isGlobal && isEditing) {
        setReaderMode(false)
      } else if (canEdit && isEditing) {
        setReaderMode(false)
      } else {
        setReaderMode(true)
      }
    }
  }, [userCanEditGlobal, selectedKbItem, canEdit, isEditing])

  useEffect(() => {
    const validateFields = () => {
      // Disable save button if either title or URL is invalid
      if (
        (articleTitle && articleTitle.trim().length === 0) ||
        (articleUrl && articleUrl.trim().length === 0)
      ) {
        setSaveDisabled(true)
      } else {
        setSaveDisabled(false)
      }
    }

    validateFields()
  }, [articleTitle, articleUrl])

  return (
    <>
      {(loading || fetchError) && (
        <div className="mx-auto w-full max-w-[100ch] px-6 pb-4 pt-10">
          {loading && <KnowledgeBaseArticleSkeleton />}
          {(!loading || isSaving) && fetchError && (
            <p className="py-10 text-red-500">
              There was a problem loading your article.
              <br />
              <span className="text-sm">{fetchError}</span>
            </p>
          )}
        </div>
      )}
      {!loading && articleData && (
        <>
          <div className="relative mx-auto mb-10 flex max-w-[112ch] flex-col gap-6 px-6 pt-10">
            <div className="px-[65px]">
              <div className="mb-2 border-b pt-4">
                <TextField
                  className="w-full"
                  value={articleTitle}
                  disabled={readerMode}
                  onChange={(event) => {
                    setArticleTitle(event.target.value)
                  }}
                  error={saveDisabled}
                  helperText={
                    articleTitle?.length === 0 ? 'Title is required' : ''
                  }
                  sx={{
                    border: 'none',
                    '& fieldset': { border: 'none' },
                    '& .MuiInputBase-input': {
                      padding: 0,
                      fontSize: '2.15rem',
                    },
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000000',
                    },
                    paddingLeft: 0,
                    marginLeft: 0,
                  }}
                  data-intercom-target="article-title-input"
                  data-testid="article-title-input"
                />
              </div>

              <div className="flex flex-wrap items-center gap-2">
                <p className="text-sm text-gray-500">
                  Updated&nbsp;
                  {dayjs.duration(updatedMinutes, 'minutes').humanize(true)} by
                </p>
                <AuthorsKnowledgebase
                  updatedByUser={articleData?.updatedByUser}
                  updatedAt={new Date(
                    articleData?.updatedAt,
                  ).toLocaleDateString('en-AU')}
                  byText=""
                />
              </div>
            </div>
            {articleData?.type === KB_ITEM_TYPE.ARTICLE && (
              <div className="px-[65px]">
                <RichTextEditor
                  id="kb-article"
                  name="kb-article"
                  defaultValue={articleText}
                  readOnly={readerMode}
                  onChange={(value) => setArticleText(value)}
                />
              </div>
            )}
            {articleData?.type === KB_ITEM_TYPE.DOCUMENT && (
              <DocumentEditor
                readerMode={readerMode}
                defaultValue={articleDocumentData as any}
                setData={(e) => {
                  setArticleDocumentData(e as unknown as OutputData)
                }}
              />
            )}
            {articleData?.type === KB_ITEM_TYPE.URL && (
              <div className="px-[65px]">
                <p className="mb-1 text-xl">
                  {articleData?.title} has opened in a new tab.
                </p>
                <p className="mb-10 text-gray-600">
                  If you have not been automatically taken to the page, click
                  the button below.
                </p>

                <a
                  href={articleData?.url}
                  target="_blank"
                  rel="noreferrer"
                  className="inline-flex items-center gap-4 rounded bg-indigo-600 px-4 py-2 text-white hover:bg-indigo-500 focus:outline focus:outline-4 focus:outline-indigo-300"
                >
                  Open {articleData?.title}
                  <ArrowTopRightOnSquareIcon className="h-4 w-4" />
                </a>
              </div>
            )}
          </div>
          <LinkedContentBadge
            resourceId={articleData?.id}
            resourceType={LinkedResourceType.KNOWLEDGE_BASE}
            returnLinkedContent={setLinkedWorkflowsRows}
          />
        </>
      )}
    </>
  )
}

export default KnowledgeBaseArticle
