import { FC } from 'react'

import { Avatar } from '@mui/material'

import { navigate, routes } from '@redwoodjs/router'

import { KBItemSorted } from 'src/components/KnowledgeCell/types'
import Button from 'src/components/Library/Button/Button'
import { stringInitials } from 'src/Util'

export type KbItemSortedWithCounts = KBItemSorted & {
  articleCount: number
  categoryCount: number
}

interface KnowledgeBaseCategoriesProps {
  knowledgeBaseCategories: KbItemSortedWithCounts[]
}

const KnowledgeBaseCategories: FC<KnowledgeBaseCategoriesProps> = ({
  knowledgeBaseCategories,
}) => {
  return (
    <div
      className="grid w-full gap-6"
      style={{
        gridTemplateColumns: 'repeat(auto-fill, minmax(25ch, 1fr))',
        gridTemplateRows: 'repeat(auto-fill, minmax(40px,auto))',
      }}
    >
      {knowledgeBaseCategories.map((item) => (
        <Button
          className="min-w-[0] justify-start !border !border-solid !border-gray-300 !bg-white px-4 py-4 text-left !normal-case hover:!border-gray-400 hover:shadow-lg"
          key={item.id}
          variant="text"
          color="secondary"
          onClick={() => navigate(routes.knowledgeWithId({ id: item.id }))}
          startIcon={
            <Avatar
              variant="rounded"
              className="h-12 w-12"
              sx={{ bgcolor: '#5046e5' }}
            >
              {stringInitials(item.title)}
            </Avatar>
          }
        >
          <div className="line-clamp-1 flex flex-col gap-0.5">
            <span className="line-clamp-1 break-all text-gray-900">
              {item?.title}
            </span>
            <span className="block text-xs font-light text-gray-400">
              {item.articleCount
                ? `${item.articleCount} Article${item.articleCount === 1 ? '' : 's'}`
                : ''}
              {item.articleCount && item.categoryCount ? ', ' : ''}
              {item.categoryCount
                ? `${item.categoryCount} Categor${item.categoryCount === 1 ? 'y' : 'ies'}`
                : ''}
            </span>
          </div>
        </Button>
      ))}
    </div>
  )
}

export default KnowledgeBaseCategories
