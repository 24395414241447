export const CREATE_REACT_MAP = gql`
  mutation CreateReactMapMutation($input: CreateReactMapInput!) {
    createReactMap(input: $input) {
      id
      clientId
      name
      description
      diagramData
      reactMapCategoryId
    }
  }
`

export const CREATE_REACT_MAPS_FROM_TEMPLATES = gql`
  mutation CreateReactMapsFromTemplatesMutation(
    $templateIds: [Int!]!
    $categoryId: Int!
  ) {
    createReactMapsFromTemplates(
      templateIds: $templateIds
      categoryId: $categoryId
    )
  }
`

export const CREATE_REACT_MAP_CATEGORY = gql`
  mutation CreateReactMapCategoryMutation(
    $input: CreateReactMapCategoryInput!
  ) {
    createReactMapCategory(input: $input) {
      id
      clientId
      name
      description
      status
      rank
      reactMaps {
        id
      }
    }
  }
`

export const UPDATE_REACT_MAP_CATEGORY = gql`
  mutation UpdateReactMapCategoryMutation(
    $id: Int!
    $input: UpdateReactMapCategoryInput!
  ) {
    updateReactMapCategory(id: $id, input: $input) {
      id
      name
      rank
      status
      description
      updatedBy
    }
  }
`
export const DELETE_REACT_MAP_CATEGORY = gql`
  mutation DeleteReactMapCategoryMutation($id: Int!) {
    deleteReactMapCategory(id: $id) {
      id
    }
  }
`
