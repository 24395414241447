import type React from 'react'

import { useMediaQuery } from 'usehooks-ts'

import { default as EmptyData } from 'src/components/Library/Empty'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import ProcessMapViewer from 'src/components/ProcessMapViewer/ProcessMapViewer'

export const QUERY = gql`
  query FindMapperViewerQuery($id: Int) {
    mapItem: reactMap(id: $id) {
      id
      name
      createdBy
      updatedBy
      legend
      nodes
      connectors
    }
  }
`

export const beforeQuery = (props) => ({
  variables: props,
  fetchPolicy: 'no-cache',
})

export const Loading = () => <LoadingSpinner />

export const Empty = () => <EmptyData />

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

interface Props {
  mapItem: any
  isPlayerView?: boolean
  isMobile?: boolean
}

export const Success: React.FC<Props> = ({ mapItem, isPlayerView }) => {
  const isMobile = useMediaQuery('(max-width: 640px)')
  return (
    <ProcessMapViewer
      mapItem={mapItem}
      isPlayerView={isPlayerView}
      isMobile={isMobile}
    />
  )
}
