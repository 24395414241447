import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { Cog6ToothIcon } from '@heroicons/react/24/outline'
import { PlusIcon } from '@heroicons/react/24/solid'
import {
  DragDropContext,
  Draggable,
  DraggableLocation,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
} from 'react-beautiful-dnd'
import {
  RankLearnerItemsMutation,
  RankLearnerItemsMutationVariables,
  UpdateReactMapMutation,
  UpdateReactMapMutationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { DragAndDropFolder } from 'src/components/Library/DragAndDropFolders/DragAndDropFolderList'
import { default as EmptyData } from 'src/components/Library/Empty'
import RANK_ITEM_MUTATION from 'src/lib/queries/learner/rank'
import { UPDATE_REACT_MAP } from 'src/lib/queries/learner/ReactMaps/ReactMap'
import { useAuth } from 'src/Providers'
import { published } from 'src/Util'

import Button from '../Library/Button/Button'
import { DragHandle } from '../Library/DragAndDropFolders/DragHandle'
import Folder from '../Library/DragAndDropFolders/Folder'
import { reorder } from '../Library/DragAndDropFolders/utils'
import Dropdown from '../Library/Dropdown/Dropdown'
import IconButton from '../Library/IconButton/IconButton'

import ReactMapSideItem from './ReactMapSideItem'
import { mapsEditRoles } from './ReactMapSidePanel'
import { ReactMapCategory, ReactMap } from './ReactMapSidePanelCell'

interface ReactMapSideItemListProps {
  categories: ReactMapCategory[]
  uncategorisedMaps: ReactMap[]
  selectedCategory: ReactMapCategory
  setSelectedCategory: Dispatch<SetStateAction<ReactMapCategory>>
  selectedMap: number
  setSelectedMap: Dispatch<SetStateAction<number>>
  handleModalToggle: (modalType: string | null) => void
  createItem: (categoryId: number) => void
  handleCreateFrom: (id: number) => void
}

const ReactMapSideItemList: FC<ReactMapSideItemListProps> = ({
  categories,
  uncategorisedMaps,
  setSelectedCategory,
  selectedMap,
  setSelectedMap,
  handleModalToggle,
  createItem,
  handleCreateFrom,
}) => {
  const { hasRole, currentUser } = useAuth()

  const uncategorisedCategory: ReactMapCategory[] =
    uncategorisedMaps?.length > 0
      ? [
          {
            id: 0,
            name: 'Uncategorised',
            rank: 0,
            reactMaps: uncategorisedMaps,
            clientId: currentUser.parentData.id,
            status: 'DRAFT',
          },
        ]
      : []

  const [orderedCategories, setOrderedCategories] =
    useState<ReactMapCategory[]>(categories)

  const [rankMapItems] = useMutation<
    RankLearnerItemsMutation,
    RankLearnerItemsMutationVariables
  >(RANK_ITEM_MUTATION, {
    onCompleted: () => {
      toast.success('Change Saved')
    },
    onError: (error) => {
      toast.error(error.message)
    },
    awaitRefetchQueries: true,
    refetchQueries: ['FindReactMapsSidePanelQuery'],
  })

  const [updateReactMap] = useMutation<
    UpdateReactMapMutation,
    UpdateReactMapMutationVariables
  >(UPDATE_REACT_MAP, {
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const saveReactMapCategoryRanking = async (reorderedItems) => {
    const reorderedItemsInput = reorderedItems.map((item, index) => ({
      id: item.id,
      rank: index + 1,
    }))

    await rankMapItems({
      variables: {
        input: { itemType: 'ReactMapCategory', items: reorderedItemsInput },
      },
    })
  }

  const saveReactMapRanking = async (reorderedItems) => {
    const reorderedItemsInput = reorderedItems.map((item, index) => ({
      id: item.id,
      rank: index + 1,
    }))

    await rankMapItems({
      variables: {
        input: { itemType: 'ReactMap', items: reorderedItemsInput },
      },
    })
  }

  const reorderCatMap = (quoteMap, source, destination, draggableId) => {
    // These values have a string ID with type+id
    // We can't use just ID's because they could conflict between dnd contexts
    const mapId = Number(draggableId.split('map')[1])
    const currentCatId = Number(source.droppableId.split('cat')[1])
    const nextCatId = Number(destination.droppableId.split('cat')[1])

    const currentCategory = quoteMap.find(
      (category) => category.id === currentCatId,
    )
    const currentCategoryIndex = quoteMap.findIndex(
      (category) => category.id === currentCatId,
    )
    const nextCategory = quoteMap.find((category) => category.id === nextCatId)

    const nextCategoryIndex = quoteMap.findIndex(
      (category) => category.id === nextCatId,
    )

    const target = currentCategory?.reactMaps[source.index]

    // moving to same list
    if (source.droppableId === destination.droppableId) {
      const reordered = reorder(
        currentCategory.reactMaps,
        source.index,
        destination.index,
      )

      const result = quoteMap
      result[currentCategoryIndex].reactMaps = reordered

      // save the order of the items in the category
      saveReactMapRanking(reordered)

      return {
        quoteMap: result,
      }
    }

    // moving to different list
    const updatedCurrentCat = [...currentCategory.reactMaps].filter(
      (_item, index) => index !== source.index,
    )

    // insert into next
    nextCategory.reactMaps.splice(destination.index, 0, target)

    const result = quoteMap
    result[currentCategoryIndex].reactMaps = updatedCurrentCat
    result[nextCategoryIndex].reactMaps = nextCategory.reactMaps

    // save the categoryId change
    updateReactMap({
      variables: {
        id: mapId,
        input: {
          reactMapCategoryId: nextCategory.id,
        },
      },
    }).then(() => {
      // save the rankings
      saveReactMapRanking(nextCategory.reactMaps)
    })

    return {
      quoteMap: result,
    }
  }

  const onNewDragEnd = (result: DropResult) => {
    // dropped nowhere
    if (!result.destination) {
      return
    }

    const source: DraggableLocation = result.source
    const destination: DraggableLocation = result.destination

    // did not move anywhere - can bail early
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return
    }

    // reordering column
    if (result.type === 'COLUMN') {
      const ordered = reorder(
        orderedCategories,
        source.index,
        destination.index,
      )

      setOrderedCategories(ordered)

      // Send and forget update category rank for changes
      saveReactMapCategoryRanking(ordered)

      // Exit
      return
    }

    // Reorder or move sub items
    const data = reorderCatMap(
      orderedCategories,
      source,
      destination,
      result.draggableId,
    )

    // Send and forget to Save that shi
    setOrderedCategories(data.quoteMap)
  }

  useEffect(() => {
    // when categories change, update the state
    setOrderedCategories(categories)
  }, [categories])

  return (
    <>
      {orderedCategories?.length === 0 &&
        uncategorisedCategory?.length === 0 && (
          <div className="grid h-full grow place-items-center">
            <EmptyData title="No Process Maps Found" />
          </div>
        )}

      {(orderedCategories?.length > 0 || uncategorisedCategory?.length > 0) && (
        <div className="p-3">
          <DragDropContext onDragEnd={onNewDragEnd}>
            <Droppable droppableId="board" type="COLUMN" direction="vertical">
              {(provided: DroppableProvided) => (
                <div
                  style={{
                    overflowX: 'hidden',
                    overflowY: 'auto',
                  }}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {uncategorisedCategory.map((category) => (
                    <div className="mb-2" key={category.id}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Folder
                          folder={category as DragAndDropFolder}
                          DragHandle={<div className="mx-2"></div>}
                          FolderTitleActions={null}
                        >
                          <div className="bg-white">
                            {category?.reactMaps?.map((map) => {
                              return (
                                <div key={map.id}>
                                  <ReactMapSideItem
                                    DragHandle={<div className="mx-2"></div>}
                                    key={map.id}
                                    item={map}
                                    selectedItem={selectedMap}
                                    setSelectedItem={setSelectedMap}
                                  />
                                </div>
                              )
                            })}
                          </div>
                        </Folder>
                      </div>
                    </div>
                  ))}
                  {orderedCategories.map((category, index: number) => (
                    <div className="mb-2" key={category.id}>
                      <Draggable
                        draggableId={'cat' + category?.id.toString()}
                        index={index}
                        key={category.id}
                      >
                        {(
                          provided: DraggableProvided,
                          _snapshot: DraggableStateSnapshot,
                        ) => (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <Folder
                              folder={category as DragAndDropFolder}
                              DragHandle={
                                <DragHandle
                                  dragHandleProps={provided.dragHandleProps}
                                  disableDragAndDrop={false}
                                />
                              }
                              FolderTitleActions={
                                <div className="flex items-center gap-2">
                                  {hasRole(mapsEditRoles) &&
                                    category.id !== 0 && (
                                      <>
                                        <div>
                                          {published(category) && (
                                            <span className="rounded-full bg-emerald-100 px-2 py-0.5 text-xs text-emerald-600">
                                              Published
                                            </span>
                                          )}
                                          {!published(category) && (
                                            <span className="rounded-full bg-amber-100 px-2 py-0.5 text-xs text-amber-600">
                                              Draft
                                            </span>
                                          )}
                                        </div>
                                        <IconButton
                                          disableRipple
                                          className="p-0.5 hover:!bg-gray-300"
                                          onClick={(event) => {
                                            event.stopPropagation()
                                            event.preventDefault()
                                            setSelectedCategory(category)
                                            handleModalToggle('category')
                                          }}
                                        >
                                          <Cog6ToothIcon className="h-6 w-6 text-gray-500" />
                                        </IconButton>
                                      </>
                                    )}
                                </div>
                              }
                            >
                              <div className="bg-white">
                                <Droppable
                                  droppableId={'cat' + category?.id.toString()}
                                  type={'QUOTE'}
                                >
                                  {(
                                    dropProvided: DroppableProvided,
                                    _dropSnapshot: DroppableStateSnapshot,
                                  ) => (
                                    <div {...dropProvided.droppableProps}>
                                      <div ref={dropProvided.innerRef}>
                                        {category?.reactMaps?.map(
                                          (map, index) => {
                                            return (
                                              <Draggable
                                                key={map.id}
                                                draggableId={
                                                  'map' + map.id?.toString()
                                                }
                                                index={index}
                                              >
                                                {(
                                                  dragProvided: DraggableProvided,
                                                  dragSnapshot: DraggableStateSnapshot,
                                                ) => (
                                                  <div
                                                    key={map.id}
                                                    ref={dragProvided.innerRef}
                                                    {...dragProvided.draggableProps}
                                                    data-is-dragging={
                                                      dragSnapshot.isDragging
                                                    }
                                                    data-testid={map.id}
                                                    data-index={index}
                                                  >
                                                    <ReactMapSideItem
                                                      DragHandle={
                                                        <DragHandle
                                                          dragHandleProps={
                                                            dragProvided.dragHandleProps
                                                          }
                                                          disableDragAndDrop={
                                                            false
                                                          }
                                                        />
                                                      }
                                                      key={map.id}
                                                      item={map}
                                                      selectedItem={selectedMap}
                                                      setSelectedItem={
                                                        setSelectedMap
                                                      }
                                                    />
                                                  </div>
                                                )}
                                              </Draggable>
                                            )
                                          },
                                        )}
                                        {dropProvided.placeholder}
                                        {hasRole(mapsEditRoles) && (
                                          <div className="flex justify-center p-2">
                                            {hasRole([
                                              'STAFFLINK',
                                              'SUPERADMIN',
                                            ]) ? (
                                              <Dropdown
                                                menuItems={[
                                                  {
                                                    label: 'Add New Map',
                                                    onClick: () => {
                                                      createItem(category.id)
                                                    },
                                                  },
                                                  {
                                                    label: 'Add From Template',
                                                    onClick: () => {
                                                      handleCreateFrom(
                                                        category.id,
                                                      )
                                                    },
                                                  },
                                                ]}
                                                buttonProps={{
                                                  startIcon: (
                                                    <PlusIcon className="h-5 w-5" />
                                                  ),
                                                  variant: 'outlined',
                                                  className:
                                                    'text-left min-w-[0] text-sm !border-dashed',
                                                }}
                                                buttonDataTestId={`create-map-button-${category.name}`}
                                              >
                                                Create Map
                                              </Dropdown>
                                            ) : (
                                              <Button
                                                size="small"
                                                startIcon={
                                                  <PlusIcon className="h-5 w-5" />
                                                }
                                                onClick={() =>
                                                  createItem(category.id)
                                                }
                                                variant="outlined"
                                                className="min-w-[0] !border-dashed text-left text-sm"
                                                buttonDataTestId={`create-map-button-${category.name}`}
                                              >
                                                Create Map
                                              </Button>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </Droppable>
                              </div>
                            </Folder>
                          </div>
                        )}
                      </Draggable>
                    </div>
                  ))}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
    </>
  )
}

export default ReactMapSideItemList
