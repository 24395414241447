import React, { FC } from 'react'

import { ArrowRightOnRectangleIcon } from '@heroicons/react/20/solid'
import { TrashIcon } from '@heroicons/react/24/outline'
import { FormLabel, Stack, Tooltip, Box } from '@mui/material'
import Button from '@mui/material/Button'
import {
  getNodesBounds,
  NodeProps,
  NodeResizer,
  useReactFlow,
  useStore,
  useStoreApi,
} from 'reactflow'

import { isEqual } from 'src/components/ReactMap/utils'

import useDetachNodes from './useDetachNodes'

const padding = 25

const handleStyle = {
  opacity: 100,
  background: '#FFFFFF',
  width: 10,
  height: 10,
  border: '1px solid #000000',
}

const GroupNode: FC<NodeProps> = ({ data, id, selected }) => {
  const store = useStoreApi()
  const { deleteElements } = useReactFlow()
  const detachNodes = useDetachNodes()
  const { hasChildNodes } = useStore((store) => {
    const childNodes = Array.from(store.nodeInternals.values()).filter(
      (n) => n.parentNode === id,
    )
    const rect = getNodesBounds(childNodes)

    return {
      minWidth: rect.width + padding * 2,
      minHeight: rect.height + padding * 2,
      hasChildNodes: childNodes.length > 0,
    }
  }, isEqual)

  const onDelete = () => {
    deleteElements({ nodes: [{ id }] })
  }
  const resizeNode = (_e, p) => {
    data.width = p.width
    data.height = p.height
  }

  const onDetach = () => {
    const childNodeIds = Array.from(store.getState().nodeInternals.values())
      .filter((n) => n.parentNode === id)
      .map((n) => n.id)

    detachNodes(childNodeIds, id)
  }

  const hexToRgba = (hex, alpha) => {
    // Remove the '#' symbol from the hex string if present
    hex = hex.replace('#', '')

    // Parse the hex values for red, green, and blue components
    const r = parseInt(hex.substring(0, 2), 16)
    const g = parseInt(hex.substring(2, 4), 16)
    const b = parseInt(hex.substring(4, 6), 16)

    // Return the rgba color with the specified alpha value
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  }

  const renderHeader = (rotate) => {
    return (
      <Box
        className={`
    ${rotate ? 'w-5 h-full' : 'h-5 w-full]'}
    flex flex-col justify-center items-center content-center
    ${data.strokeColor ? `border-${data.strokeColor}` : ''}

  `}
        style={{
          backgroundColor: hexToRgba(data?.shapeColor || '#FFFFFF', 0.5),
        }}
      >
        <Stack direction="row">
          <FormLabel
            className={`font-mono font-bold text-xs ${
              rotate ? 'rotate-[-90deg]' : 'rotate-0'
            } flex justify-center items-center whitespace-nowrap content-center`}
            sx={{
              color: data.labelColor ? data.labelColor : '#000000',
            }}
          >
            {data.url ? (
              <a
                href={data.url}
                target="_blank"
                className="font-mono font-bold text-xs underline"
                style={{
                  color: data.fontColor ? data.fontColor : '#000000',
                }}
                rel="noreferrer"
              >
                {data.label}
              </a>
            ) : (
              <FormLabel
                className="font-mono font-bold text-black text-xs"
                sx={{
                  color: data.fontColor ? data.fontColor : '#000000',
                }}
              >
                {data.label}
              </FormLabel>
            )}
          </FormLabel>
        </Stack>
      </Box>
    )
  }

  return (
    <Box
      className={'h-full'}
      style={{
        backgroundColor: hexToRgba(data?.shapeColor || '#FFFFFF', 0.5),
      }}
    >
      {selected && (
        <NodeResizer
          onResize={(event, params) => resizeNode(event, params)}
          minWidth={100}
          handleStyle={handleStyle}
          minHeight={100}
        />
      )}
      <Stack direction="column" className="h-full flex justify-between">
        {renderHeader(data.shape === 'left-swimlane')}

        <Stack direction={'row'} className={'justify-start'}>
          <Tooltip title={'Delete Group'}>
            <div>
              <Button
                fullWidth={false}
                variant="outlined"
                className="min-w-[0] p-1 rounded-full"
                onClick={onDelete}
              >
                <TrashIcon className="w-3 h-3" />
              </Button>
            </div>
          </Tooltip>

          {hasChildNodes && (
            <Tooltip title={'Ungroup'}>
              <div>
                <Button
                  fullWidth={false}
                  variant="outlined"
                  className="min-w-[0] p-1 rounded-full"
                  onClick={onDetach}
                >
                  <ArrowRightOnRectangleIcon className="w-3 h-3 " />
                </Button>
              </div>
            </Tooltip>
          )}
        </Stack>
      </Stack>
    </Box>
  )
}

export default GroupNode
