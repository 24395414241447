import { useEffect, useState } from 'react'

const usePulse = (isActive, duration = 2000) => {
  const [opacity, setOpacity] = useState(1)

  useEffect(() => {
    let intervalId

    if (isActive) {
      let isIncreasing = false
      intervalId = setInterval(() => {
        setOpacity((prevOpacity) => {
          if (prevOpacity <= 0.5) isIncreasing = true
          if (prevOpacity >= 1) isIncreasing = false

          return isIncreasing ? prevOpacity + 0.1 : prevOpacity - 0.1
        })
      }, duration / 20) // Adjust for smoother transition
    } else {
      setOpacity(1) // Reset opacity when not active
    }

    return () => clearInterval(intervalId)
  }, [isActive, duration])

  return opacity
}

export default usePulse
