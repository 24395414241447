import { useEffect, useState, type FC } from 'react'

import {
  Cog6ToothIcon,
  DocumentPlusIcon,
  LockClosedIcon,
  LockOpenIcon,
} from '@heroicons/react/24/outline'
import { MenuItem, Select, TextField, Tooltip } from '@mui/material'
import { MembershipRole } from 'types/graphql'

import { navigate, routes, useLocation, useParams } from '@redwoodjs/router'

import LayoutSettingsDrawer from 'src/components/HubDash/LayoutSettingsDrawer/LayoutSettingsDrawer'
import AutoComplete from 'src/components/Library/Autocomplete/Autocomplete'
import Button from 'src/components/Library/Button/Button'
import IconButton from 'src/components/Library/IconButton'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import useHubDashStore from 'src/lib/stores/hubDashStore'
import { useAuth } from 'src/Providers'

import type {
  HubDashLayout,
  HubDashMembership,
  HubDashMembershipGroup,
} from '../HubDashLayoutListCell'
import type { HubDashCardType, HubDashUser } from '../lib/types'

interface HubDashHeaderProps {
  layouts: HubDashLayout[]
  memberships: HubDashMembership[]
  groups: HubDashMembershipGroup[]
}

const HubDashHeader: FC<HubDashHeaderProps> = ({
  layouts,
  memberships,
  groups,
}) => {
  const { hasRole, currentUser } = useAuth()
  const isSupport = currentUser.isClientAlias
  const adminRoles: MembershipRole[] = ['ADMIN', 'OWNER']
  const isAdmin = hasRole(adminRoles)

  const { layoutId } = useParams()

  const [
    layout,
    setLayout,
    layoutData,
    setLayoutData,
    isUnlocked,
    setIsUnlocked,
    userList,
    setUserList,
    setSelectedUsers,
  ] = useHubDashStore((state) => [
    state.layout,
    state.setLayout,
    state.layoutData,
    state.setLayoutData,
    state.isUnlocked,
    state.setIsUnlocked,
    state.userList,
    state.setUserList,
    state.setSelectedUsers,
  ])

  const [selectedUserItems, setSelectedUserItems] = useState<HubDashUser[]>([])
  const [editMode, setEditMode] = useState<boolean>(false)

  const { trackEvent } = useAnalytics()
  const params = useParams()
  const location = useLocation()

  useEffect(() => {
    const groupsList: HubDashUser[] = groups.map((group) => {
      return {
        id: group?.id,
        name: group?.name,
        avatarUrl: '',
        baserowUserId: null,
        listType: 'Group',
      }
    })
    const usersList: HubDashUser[] = memberships.map((membership) => {
      return {
        id: membership?.user?.id,
        name: membership?.user?.name,
        avatarUrl: membership?.user?.avatarUrl,
        baserowUserId: membership?.user?.baserowUserId,
        groups: membership?.membershipGroups?.map((group) => {
          return {
            id: group?.membershipGroup?.id,
            name: group?.membershipGroup?.name,
          }
        }),
        listType: 'User',
      }
    })
    const combinedList = [...groupsList, ...usersList]
    setUserList(combinedList)
  }, [memberships, setUserList])

  useEffect(() => {
    if (selectedUserItems.length > 0) {
      const cleanUsers = selectedUserItems.map((item) => {
        if (item.listType === 'Group') {
          const groupUsers = userList.filter((u) => {
            if (u.listType === 'User') {
              return u.groups.some((group) => group.id === item.id)
            } else {
              return false
            }
          })
          return groupUsers.map((user) => user.name)
        } else {
          return item.name
        }
      })
      const flatUsers = cleanUsers.flat()
      const uniqueUsers = [...new Set(flatUsers)]

      setSelectedUsers(uniqueUsers)
    } else {
      setSelectedUsers([])
    }
  }, [selectedUserItems])

  const [layoutSettingsOpen, setLayoutSettingsOpen] = useState(false)

  const handleLayoutChange = (e) => {
    navigate(
      routes.hubDashWithId({
        layoutId: e.target.value,
      }),
    )
  }

  useEffect(() => {
    if (layouts.length > 0) {
      const structuredLayouts = layouts.map((layout) => {
        return {
          ...layout,
          cards: layout.HubDashLayoutToHubDashCard.map((card) => {
            return {
              id: card.hubDashCard.id,
              relationId: card.id,
              position: card.position,
              name: card.hubDashCard.name,
              type: card.hubDashCard.type,
              source: card.hubDashCard.source,
              cardData: card.hubDashCard.cardData,
              cardSettings: card.hubDashCard.cardSettings,
              cardColors: card.hubDashCard.hubDashColorMap,
              linkedResources: card.linkedResources,
            } as HubDashCardType
          }),
        }
      })
      if (!layout || layout !== Number(layoutId)) {
        if (layoutId) {
          const selectedLayout = structuredLayouts.find(
            (l) => l.id === Number(layoutId),
          )
          setLayout(Number(layoutId))
          setLayoutData(selectedLayout)
        } else {
          navigate(
            routes.hubDashWithId({
              layoutId: Number(layoutId) || layouts[0].id,
              ...params,
            }),
          )
        }
      } else {
        const selectedLayout = structuredLayouts.find((l) => l.id === layout)
        setLayoutData(selectedLayout || structuredLayouts[0])
      }
    }
  }, [layouts, layoutId, params, location])

  useEffect(() => {
    if (
      (layoutData?.userFilterType === 'CURRENT' ||
        layoutData?.userFilterType === 'ANY') &&
      !isSupport
    ) {
      setSelectedUserItems(
        userList.filter(
          (user) =>
            user.id === currentUser.userData.id && user.listType === 'User',
        ),
      )
    }
  }, [layoutData?.userFilterType])

  const userFilterEnabled =
    (isAdmin && layoutData?.allowUserFilterOverride) ||
    layoutData?.userFilterType === 'ANY'

  return (
    <div className="flex h-10 w-full justify-between gap-2">
      {layouts.length === 0 ? (
        <>
          {isAdmin && (
            <Button
              className="w-48"
              onClick={() => {
                setLayoutSettingsOpen(true)
              }}
              buttonDataTestId="create-layout"
            >
              Create Layout
            </Button>
          )}
        </>
      ) : (
        <div className="flex w-full justify-between gap-2 pr-2">
          <div className="flex w-full gap-2">
            <div
              data-intercom-target="hubdash-layout-select"
              data-testid="hubdash-layout-select"
            >
              <Select
                placeholder="Layout"
                value={layout || ''}
                size="small"
                onChange={(e) => handleLayoutChange(e)}
                className="w-72"
              >
                {layouts.map((layout) => (
                  <MenuItem
                    key={layout.id}
                    value={layout.id}
                    data-testid={`layout-select-item-${layout.id}`}
                  >
                    {layout.name}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <Tooltip
              title={
                (!layoutData?.allowUserFilterOverride || !isAdmin) &&
                layoutData?.userFilterType === 'CURRENT'
                  ? 'User filtering is locked for this layout'
                  : ''
              }
            >
              <div
                className="w-full flex-grow"
                data-intercom-target="hubdash-user-select"
              >
                <AutoComplete
                  disabled={!userFilterEnabled}
                  options={userList}
                  size="small"
                  className="w-full"
                  getOptionLabel={(option) => option?.name}
                  groupBy={(option) => option.listType}
                  renderGroup={(params) => (
                    <li key={params.key}>
                      <div>
                        <div className="px-8">
                          <div
                            className={`p-2 text-gray-500 ${
                              params.group === 'User'
                                ? 'border-t-2 border-gray-300'
                                : ''
                            }`}
                          ></div>
                        </div>
                        <div className="pl-8 font-bold">{params.group}s</div>
                      </div>
                      <div className="pl-4">{params.children}</div>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Users"
                      className="z-10 bg-white"
                    />
                  )}
                  limitTags={3}
                  onChange={(_, newValue) => {
                    setSelectedUserItems(newValue)
                    trackEvent('HubDash', 'Select Users')
                  }}
                  value={selectedUserItems}
                  disableCloseOnSelect
                  multiple
                />
              </div>
            </Tooltip>
          </div>
          <div className="flex gap-2">
            {isAdmin && (
              <div className="flex gap-2">
                <Tooltip title="Add Layout">
                  <span
                    data-intercom-target="add-layout"
                    data-testid="add-layout"
                  >
                    <IconButton
                      onClick={() => {
                        setEditMode(false)
                        setLayoutSettingsOpen(true)
                      }}
                      className="h-10 w-10"
                    >
                      <DocumentPlusIcon className="h-6 w-6" />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title={`${isUnlocked ? 'Lock' : 'Unlock'} Layout`}>
                  <span
                    data-intercom-target={`${isUnlocked ? 'lock' : 'unlock'}-layout`}
                    data-testid={`${isUnlocked ? 'lock' : 'unlock'}-layout`}
                  >
                    <IconButton
                      onClick={() => {
                        setIsUnlocked(!isUnlocked)
                        trackEvent(
                          'HubDash',
                          `${isUnlocked ? 'Lock' : 'Unlock'} Layout`,
                        )
                      }}
                      className="h-10 w-10"
                    >
                      {isUnlocked ? (
                        <LockOpenIcon className="h-6 w-6" />
                      ) : (
                        <LockClosedIcon className="h-6 w-6" />
                      )}
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Layout Settings">
                  <span data-intercom-target="layout-settings">
                    <IconButton
                      onClick={() => {
                        setEditMode(true)
                        setLayoutSettingsOpen(true)
                        trackEvent('HubDash', 'Click Layout Settings')
                      }}
                      className="h-10 w-10"
                    >
                      <Cog6ToothIcon className="h-6 w-6" />
                    </IconButton>
                  </span>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      )}
      <LayoutSettingsDrawer
        layoutSettingsOpen={layoutSettingsOpen}
        setLayoutSettingsOpen={setLayoutSettingsOpen}
        editMode={editMode}
        setEditMode={setEditMode}
      />
    </div>
  )
}

export default HubDashHeader
