import { FC, MouseEvent, useState } from 'react'

import { Cog6ToothIcon, GlobeAltIcon } from '@heroicons/react/24/outline'
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import { KnowledgeBaseItemStatus } from 'types/graphql'

import KBStatusChip from './KBStatusChip'

interface KBFolderTitleActionBarProps {
  id: number
  onClick: (id: number) => void
  onArchive: (id: number) => void
  userCanEdit: boolean
  isDraft: boolean
  isTablet: boolean
  isGlobal?: boolean
}

const KBFolderTitleActionBar: FC<KBFolderTitleActionBarProps> = ({
  id,
  onClick,
  onArchive,
  userCanEdit,
  isDraft,
  isTablet,
  isGlobal,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const stopEvents = (event) => {
    event.stopPropagation()
    event.preventDefault()
  }

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    // Set the anchor
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    // Unset anchor
    setAnchorEl(null)
  }

  const handleSettings = () => {
    // Close the menu - modal popped up
    handleClose()

    // Set as editing category
    onClick(id)
  }

  const handleArchive = () => {
    // Close the menu - confirm popped up
    handleClose()

    // Archive it
    onArchive(id)
  }

  return (
    <div className="flex items-center gap-1 text-gray-500">
      {isGlobal && (
        <Tooltip title={'Global Category'}>
          <GlobeAltIcon className={'text-blue-400 h-4 w-4'} />
        </Tooltip>
      )}
      {userCanEdit && isDraft && !isTablet && (
        <KBStatusChip
          status={'DRAFT' as KnowledgeBaseItemStatus}
          size="small"
        />
      )}
      <IconButton
        disableRipple
        className="p-0.5 hover:!bg-gray-300"
        onClick={(event) => {
          stopEvents(event)
          handleClick(event)
        }}
      >
        <Cog6ToothIcon className="w-6 h-6" />
      </IconButton>
      <Menu
        elevation={5}
        id={'folder-settings-' + id}
        anchorEl={anchorEl}
        open={open}
        onClose={(event) => {
          stopEvents(event)
          handleClose()
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          borderRadius: '20px',
          '& .MuiMenu-list': {
            padding: '0',
          },
          '& .MuiMenuItem-root ': {
            padding: '10px 15px',
          },
        }}
      >
        <MenuItem
          disableRipple
          onClick={(event) => {
            stopEvents(event)
            handleSettings()
          }}
        >
          Edit Category
        </MenuItem>
        <MenuItem
          disableRipple
          onClick={(event) => {
            stopEvents(event)
            handleArchive()
          }}
          className="text-red-500 hover:bg-red-50"
        >
          Archive Category
        </MenuItem>
      </Menu>
    </div>
  )
}

export default KBFolderTitleActionBar
