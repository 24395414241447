import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import { Divider } from '@mui/material'

import { routes } from '@redwoodjs/router'

import {
  LinkedRowList,
  LinkOut,
  type LinkedResourceRow,
} from '../Library/LinkedContentBadge/LinkedContentBadge'

import type { CellMapInTask } from './ReactMapEditorCell'

interface ReactMapDeleteDescriptionProps {
  mapInTasks: CellMapInTask
  linkedWorkflowsRows: LinkedResourceRow[]
}

const ReactMapDeleteDescription = ({
  mapInTasks,
  linkedWorkflowsRows,
}: ReactMapDeleteDescriptionProps) => {
  return (
    <div>
      {mapInTasks?.length > 0 && (
        <>
          <p className="my-3">
            This Process Map is linked to Learner Courses.
            <br />
            Archiving it will remove it from the following Lessons:
          </p>
          <ul>
            {mapInTasks.map((task) => (
              <li key={task.id}>
                <LinkOut
                  to={routes.editLearnerCourse({
                    id: task.learnerActivity.learnerCourseId,
                  })}
                >
                  <span>{task.name}</span>
                </LinkOut>
              </li>
            ))}
          </ul>
        </>
      )}
      {linkedWorkflowsRows?.length > 0 && mapInTasks?.length > 0 && (
        <Divider className="my-3" />
      )}
      {linkedWorkflowsRows?.length > 0 && (
        <>
          <p className="my-3">
            This Process Map is linked to HubDash.
            <br />
            Archiving it will remove it from the following linked Cards:
          </p>
          <LinkedRowList linkedWorkflowsRows={linkedWorkflowsRows} />
        </>
      )}
    </div>
  )
}

export default ReactMapDeleteDescription
