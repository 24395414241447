import { FC, useCallback, useEffect, useState } from 'react'

import { useApolloClient, useLazyQuery } from '@apollo/client'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { Tooltip, useMediaQuery } from '@mui/material'
import { captureException } from '@sentry/browser'
import { findAncestors } from 'api/src/common/collections'
import { PROD_CLIENT } from 'api/src/common/enums'
import { isNil } from 'ramda'
import type {
  GetKnowledgeBaseItemQuery,
  GetKnowledgeBaseItemQueryVariables,
} from 'types/graphql'

import { navigate, routes, useLocation } from '@redwoodjs/router'
import { toast } from '@redwoodjs/web/toast'

import useACL from 'src/lib/hooks/ACL/useACL'
import useConfirm from 'src/lib/hooks/Confirmation/useConfirm'
import useLocalStorage from 'src/lib/hooks/UseLocalStorage'
import { KB_ADMIN_ROLES } from 'src/pages/KnowledgePage/KnowledgePage'
import { useAuth } from 'src/Providers'

import { KB_ITEM_TYPE, KnowledgeBaseItemStatus, QUERY } from '../KnowledgeCell'
import { KBItemSorted, type KBItem } from '../KnowledgeCell/types'
import IconButton from '../Library/IconButton/IconButton'
import {
  LinkedRowList,
  type LinkedResourceRow,
} from '../Library/LinkedContentBadge/LinkedContentBadge'

import KnowledgeBaseArticle, {
  KB_ITEM_QUERY,
} from './Article/KnowledgeBaseArticle'
import KnowledgeBaseCategory from './Category/KnowledgeBaseCategory'
import KnowledgeBaseHome from './Home/KnowledgeBaseHome'
import useCreateMutation from './Hooks/useCreateMutation'
import useOptimisticUpdateMutation from './Hooks/useOptimisticUpdateMutation'
import useUpdateMutation from './Hooks/useUpdateMutation'
import KnowledgeBaseSidebar from './KnowledgeBaseSidebar'
import KnowledgeHeader from './KnowledgeHeader'
import CreateEditCategoryModal, {
  ModalState,
} from './Modals/CreateEditCategoryModal'

interface KnowledgeBaseProps {
  id: number
  kbItems: KBItemSorted[]
  statuses: KnowledgeBaseItemStatus[]
  canEdit: boolean
}

const findKbItemById = (id: number, kbItems: KBItemSorted[]): KBItemSorted => {
  return kbItems?.find((kbItem) => Number(kbItem.id) === Number(id)) ?? null
}

export interface CreateArgs {
  parent?: KBItemSorted
  createType: KB_ITEM_TYPE.CATEGORY | KB_ITEM_TYPE.DOCUMENT
  title?: string
  isGlobal?: boolean
}

const KnowledgeBase: FC<KnowledgeBaseProps> = ({
  id,
  kbItems: kbItemsOriginal,
  statuses,
  canEdit,
}) => {
  const [kbItems, setKbItems] = useState<KBItemSorted[]>(kbItemsOriginal)

  useEffect(() => {
    setKbItems(kbItemsOriginal)
  }, [kbItemsOriginal])

  const confirm = useConfirm()
  const { currentUser } = useAuth()
  const isMobile = useMediaQuery('(max-width: 640px)')

  const isAdmin = KB_ADMIN_ROLES.includes(currentUser?.membershipData.role)

  const location = useLocation()
  const isEditUrl =
    location.pathname.toLowerCase() ===
    routes.knowledgeEditWithId({ id: id }).toLowerCase()

  const [isEditing, setIsEditing] = useState(isAdmin && isEditUrl)

  // State to track if there are changes in the article in realtime
  const [hasChanges, setHasChanges] = useState(false)

  // use localstorage to store sidebar state
  const [sidebarOpen, setSidebarOpen] = useLocalStorage<boolean>(
    `memberId-${currentUser?.membershipData?.id}-kb-sidebar-expanded`,
    !isMobile,
  )

  // Set sidebar state based on the device type only on component mount
  useEffect(() => {
    if (isMobile) {
      setSidebarOpen(false)
    }
  }, [isMobile])

  const toggleSidebar = () => setSidebarOpen((prev) => !prev)

  const [selectedKbItem, setSelectedKbItem] = useState<KBItemSorted>(
    findKbItemById(id, kbItems),
  )
  const [selectedKbEditItem, setSelectedKbEditItem] =
    React.useState<KBItem>(null)
  const apolloClient = useApolloClient()

  const { saveACL } = useACL({
    resourceType: 'KnowledgeBaseItem',
    resourceId: null,
    principalTypes: ['MEMBERSHIP', 'MEMBERSHIPGROUP'],
  })

  useEffect(() => {
    setIsEditing(isEditUrl && isAdmin)
  }, [isEditUrl, isAdmin])

  useEffect(() => {
    if (selectedKbItem) {
      const selectedIndex = kbItems.findIndex(
        (item) => item.id === selectedKbItem.id,
      )

      if (selectedIndex !== -1) {
        // Make sure the item exists in kbItems
        // Update both states with the found item
        const foundItem = kbItems[selectedIndex]
        setSelectedKbEditItem(foundItem)
        setSelectedKbItem(foundItem)
      }
    }
  }, [kbItems, selectedKbItem])

  useEffect(() => {
    window.Intercom('update', {
      knowledge_base_sidebar_open: sidebarOpen,
    })
  }, [sidebarOpen])

  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [isDuplicating, setIsDuplicating] = useState<boolean>(false)
  const [isArchiving, setIsArchiving] = useState<boolean>(false)

  const isArticle = selectedKbItem?.type !== KB_ITEM_TYPE.CATEGORY // For VIEW purposes - anything but a CATEGORY is classed as an "article" (Document, Link, Article)
  const isCategory = selectedKbItem?.type === KB_ITEM_TYPE.CATEGORY
  const [modalMode, setModalMode] = useState<ModalState>(null)

  const [saveDisabled, setSaveDisabled] = useState(false)
  const selectedAncestors = findAncestors(selectedKbItem, kbItems, true)

  const [linkedWorkflowsRows, setLinkedWorkflowsRows] = useState<
    LinkedResourceRow[]
  >([])

  // Define the type for the loading state
  type LoadingState = {
    id: number | null
    status: boolean
  }
  const [isLoading, setIsLoading] = useState<LoadingState>({
    id: null,
    status: false,
  })

  const setLoadingStatus = (id: number | null, status: boolean) => {
    setIsLoading({
      ...isLoading,
      id: id,
      status: status,
    })
  }

  const onSaveClick = async (saveArgs) => {
    setIsSaving(true)

    const {
      id,
      title,
      url,
      text,
      document,
      parent,
      isGlobal,
      isEditing,
      optimisticallyUpdate,
    } = saveArgs

    if (optimisticallyUpdate) {
      try {
        await updateMutationOptimistic({
          kbItemId: id,
          title,
          url,
          text,
          document,
          parent,
          isGlobal,
        })
        setIsSaving(false)
        // update item in kbItems
        setKbItems((prevKbItems) =>
          prevKbItems.map((kbItem) => {
            if (kbItem.id === id) {
              return {
                ...kbItem,
                title,
                url,
                text,
                document,
                parent,
                isGlobal,
              }
            }
            return kbItem
          }),
        )
      } catch (error) {
        captureException(error, {
          extra: { message: 'KB: Error Updating KB item' },
        })
        toast.error('Error updating the knowledge base item.')
        setIsSaving(false)
      }
    } else {
      //  need to refetch the data after updating
      try {
        await updateMutation({
          kbItemId: id,
          title,
          url,
          text,
          document,
          parent,
          isGlobal,
        })
        apolloClient.refetchQueries({
          include: [QUERY],
        })
        if (!isEditing) {
          toast.success('Knowledge base item updated.')
          setIsSaving(false)
        } else {
          setIsSaving(false)
        }
      } catch (error) {
        captureException(error, {
          extra: { message: 'KB: Error Updating KB item' },
        })
        toast.error('Error updating the knowledge base item.')
        setIsSaving(false)
      }
    }
  }

  const updateMutationOptimistic = useOptimisticUpdateMutation()

  const updateMutation = useUpdateMutation({
    refetchQuery: QUERY,
    statuses,
    asAdmin: isAdmin,
  })

  const createMutation = useCreateMutation({
    refetchQuery: QUERY,
    statuses,
    asAdmin: isAdmin,
  })

  const onCreateClick = useCallback(
    async (createArgs: CreateArgs) => {
      const { parent, createType, title, isGlobal } = createArgs
      setIsSaving(true)
      if (createType === 'CATEGORY') {
        try {
          const result = await createMutation({
            title: title,
            url: null,
            text: null,
            document: null,
            type: 'CATEGORY',
            parentId: parent?.id,
            isGlobal: isGlobal,
          })
          if (result.data?.createKnowledgeBaseItem) {
            const newItem = result.data.createKnowledgeBaseItem
            setIsEditing(true)
            toast.success('Knowledge Base Category created.')
            navigate(routes.knowledgeWithId({ id: newItem.id }))
          } else {
            throw new Error('Failed to create knowledge base item')
          }
          setIsSaving(false)
          return result.data.createKnowledgeBaseItem
        } catch (error) {
          captureException(error, {
            extra: { message: 'KB: Error Creating KB item Category' },
          })
          toast.error('Error creating the knowledge base item.', error)
        }
      } else if (createType === 'DOCUMENT') {
        setLoadingStatus(parent?.id, true)
        setSelectedKbEditItem(null)

        try {
          const result = await createMutation({
            title: 'NEW ARTICLE',
            url: null,
            text: null,
            document: null,
            type: 'DOCUMENT',
            parentId: parent?.id,
            isGlobal: isGlobal,
          })

          if (result.data?.createKnowledgeBaseItem) {
            const newItem = result.data.createKnowledgeBaseItem

            await saveACL({
              resourceId: newItem.id,
            })

            setIsEditing(true)
            toast.success('Knowledge Base Article created.')
            navigate(routes.knowledgeEditWithId({ id: newItem.id }))
            setLoadingStatus(null, false)
            setIsSaving(false)
          } else {
            throw new Error('Failed to create knowledge base item')
          }

          return result.data.createKnowledgeBaseItem
        } catch (error) {
          captureException(error, {
            extra: { message: 'KB: Error Creating KB item' },
          })
          toast.error('Error creating the knowledge base item.')
          setLoadingStatus(null, false)
        }
      }
    },
    [createMutation],
  )

  const onArchiveClick = useCallback(
    async (kbItem: KBItemSorted) => {
      // Check that the linked resources match the archiving item
      const linkedResourcesOnArchivingKbItem = linkedWorkflowsRows?.filter(
        (link) => {
          return link?.resourceId === kbItem?.id
        },
      )

      await confirm({
        title: 'Are you sure?',
        description: (
          <div>
            <p>Do you want to archive {kbItem.title}?</p>
            {linkedResourcesOnArchivingKbItem?.length > 0 && (
              <>
                <p className="my-3">
                  This Article is linked to HubDash.
                  <br />
                  Archiving it will remove it from the following linked Cards:
                </p>
                <LinkedRowList
                  linkedWorkflowsRows={linkedResourcesOnArchivingKbItem}
                />
              </>
            )}
          </div>
        ),
        confirmationText: 'Archive',
        confirmationButtonProps: { color: 'error', className: 'bg-red-500' },
      })

      try {
        setIsArchiving(true)
        await updateMutation({ kbItemId: kbItem.id, status: 'ARCHIVED' })
        toast.success('Knowledge base item has been archived.')
        navigate(routes.knowledge())
        setIsArchiving(false)
      } catch (error) {
        if (error) {
          captureException(error, {
            extra: { message: 'KB: Error Archiving KB item' },
          })
          toast.error('Error archiving the knowledge base item.')
          setIsArchiving(false)
        }
      }
      // user cancelled, do nothing
      setIsArchiving(false)
    },
    [updateMutation, linkedWorkflowsRows],
  )

  const [fetchError, setFetchError] = useState<string>(null)
  const [getKbArticleData] = useLazyQuery<
    GetKnowledgeBaseItemQuery,
    GetKnowledgeBaseItemQueryVariables
  >(KB_ITEM_QUERY, {
    onCompleted: (data) => {
      return data
    },
    onError: (error) => {
      setFetchError(error.message)

      return null
    },
  })

  const onDuplicateClick = useCallback(
    async (kbItem: KBItemSorted) => {
      try {
        await confirm({
          title: 'Are you sure?',
          description: `Do you want to duplicate ${kbItem.title}?`,
          confirmationText: 'Duplicate',
        })

        setIsDuplicating(true)

        // Fetch article data
        const { data } = await getKbArticleData({
          variables: {
            id: kbItem?.id,
          },
        })

        if (!data) {
          throw new Error('Failed to fetch knowledge base item data.')
        }

        // Perform duplication
        const result = await createMutation({
          title: kbItem.title + ' (Copy)',
          url: kbItem.url,
          document: data.kbItem.document,
          type: kbItem.type,
          parentId: kbItem.parentId,
          isGlobal: kbItem.isGlobal,
        })

        if (!result.data?.createKnowledgeBaseItem) {
          throw new Error('Failed to create duplicated knowledge base item.')
        }

        setIsDuplicating(false)

        const newItem = result.data.createKnowledgeBaseItem
        setIsEditing(true)
        toast.success('Knowledge base item has been duplicated.')
        navigate(routes.knowledgeEditWithId({ id: newItem.id }))
      } catch (error) {
        captureException(error, {
          extra: { message: 'KB: Error Duplicating KB item' },
        })
        toast.error('Error duplicating the knowledge base item.')
        setIsDuplicating(false)
      }
    },
    [createMutation, fetchError],
  )

  const [isPublished, setIsPublished] = useState(
    selectedKbItem?.status === 'PUBLISHED',
  )

  const togglePublishedStatus = async (kbItem: KBItemSorted) => {
    const { id, status, parentId } = kbItem

    // Find the parent item if exists, else it's null
    const parent = findKbItemById(parentId, kbItems)
    const newStatus = status === 'PUBLISHED' ? 'DRAFT' : 'PUBLISHED'
    // set editing to false before updating
    if (status === 'DRAFT') {
      setIsEditing(false)
    }

    try {
      setIsPublished(!isPublished)
      await updateMutation({ kbItemId: id, status: newStatus, parent })
      toast.success('Knowledge base article status updated.')
      // update item in kbItems
      setKbItems((prevKbItems) =>
        prevKbItems.map((kbItem) => {
          if (kbItem.id === id) {
            return {
              ...kbItem,
              status: newStatus,
            }
          }
          return kbItem
        }),
      )
    } catch (error) {
      captureException(error, {
        extra: { message: 'KB: Error Updating KB Item Status' },
      })
      toast.error('Error updating published status.')
    }
  }

  useEffect(() => {
    if (selectedKbItem?.status === 'PUBLISHED') {
      setIsPublished(true)
    } else {
      setIsPublished(false)
    }
  }, [selectedKbItem])

  useEffect(() => {
    // if editing, we don't run the below
    if (id && !isEditing) {
      // Update with found item
      const matchingKbItem = findKbItemById(id, kbItems)

      if (!matchingKbItem) {
        // Nothing matched the ID - got to KB Home
        navigate(routes.knowledge())
      } else {
        // Select KB Item
        setSelectedKbItem(matchingKbItem)
      }
    } else {
      // Reset Selected States
      setSelectedKbItem(null)
    }
    // Reset linked when we change article/category
    setLinkedWorkflowsRows([])
  }, [id])

  useEffect(() => {
    // if editing and ID is set, find the item and set it
    if (id && isEditing) {
      const matchingKbItem = findKbItemById(id, kbItems)
      setSelectedKbItem(matchingKbItem)
    }
  }, [id, isEditing, kbItems])

  // Editing Global is Restricted to Stafflink Client and users with editing permission only
  const userCanEditGlobal =
    currentUser?.parentData?.id === PROD_CLIENT.STAFFLINK

  const [categoryModalOpen, setCategoryModalOpen] = useState(false)

  const handleSideBarEdit = (id: number) => {
    const matchingKbItem = findKbItemById(id, kbItems)
    if (matchingKbItem) {
      setSelectedKbEditItem(matchingKbItem)
      setModalMode(ModalState.EDIT)
      setCategoryModalOpen(true)
    }
  }

  return (
    <>
      <div className="flex w-full grow overflow-hidden overflow-y-auto bg-white">
        <KnowledgeBaseSidebar
          sidebarOpen={sidebarOpen}
          kbItems={kbItems}
          selectedKbItem={selectedKbItem}
          setSelectedKbEditItem={setSelectedKbEditItem}
          setSelectedKbItem={setSelectedKbItem}
          handleSideBarEdit={handleSideBarEdit}
          onCreateClick={onCreateClick}
          onArchiveClick={onArchiveClick}
          toggleSidebar={toggleSidebar}
          canEdit={canEdit}
          setCategoryModalOpen={setCategoryModalOpen}
          setModalMode={setModalMode}
          isLoading={isLoading}
          hasChanges={hasChanges}
        />

        <div
          className="flex h-full w-full flex-col overflow-hidden"
          style={{
            transitionTimingFunction: 'ease-in-out',
            transitionDuration: '300ms',
          }}
        >
          {!isNil(selectedKbItem) && (
            <div className="flex h-[50px] w-full items-center border-b bg-white px-4 sm:min-h-[75px]">
              <div
                className={`flex items-center ${sidebarOpen ? 'relative' : 'absolute'}`}
              >
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    sidebarOpen
                      ? 'Hide Knowledge Base List'
                      : 'Show Knowledge Base List'
                  }
                >
                  <div>
                    <IconButton
                      className={
                        'flex-shrink rounded-lg bg-gray-100 p-2 hover:!bg-gray-200'
                      }
                      onClick={() => {
                        toggleSidebar()
                      }}
                    >
                      {sidebarOpen ? (
                        <ChevronLeftIcon className="h-4 w-4" />
                      ) : (
                        <ChevronRightIcon className="h-4 w-4" />
                      )}
                    </IconButton>
                  </div>
                </Tooltip>
              </div>

              <KnowledgeHeader
                selectedKbItem={selectedKbItem}
                setSelectedKbItem={setSelectedKbItem}
                setSelectedKbEditItem={setSelectedKbEditItem}
                togglePublishedStatus={togglePublishedStatus}
                selectedAncestors={selectedAncestors}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                isSaving={isSaving}
                onArchiveClick={onArchiveClick}
                onDuplicateClick={onDuplicateClick}
                userCanEdit={canEdit}
                isPublished={isPublished}
                saveDisabled={saveDisabled}
                setCategoryModalOpen={setCategoryModalOpen}
                setModalMode={setModalMode}
                sidebarOpen={sidebarOpen}
                isDuplicating={isDuplicating}
                isArchiving={isArchiving}
              />
            </div>
          )}
          <div className="relative grow overflow-y-auto">
            {!isEditing && !selectedKbItem && (
              <KnowledgeBaseHome
                kbItems={kbItems}
                kbListExpanded={sidebarOpen}
                toggleSidebar={toggleSidebar}
                canEdit={canEdit}
              />
            )}

            {selectedKbItem && isArticle && (
              <KnowledgeBaseArticle
                selectedKbItem={selectedKbItem}
                kbItems={kbItems}
                canEdit={canEdit}
                userCanEditGlobal={userCanEditGlobal}
                isEditing={isEditing}
                onSaveClick={onSaveClick}
                saveDisabled={saveDisabled}
                setSaveDisabled={setSaveDisabled}
                isSaving={isSaving}
                setHasChanges={setHasChanges}
                setLinkedWorkflowsRows={setLinkedWorkflowsRows}
              />
            )}
            {selectedKbItem && isCategory && (
              <KnowledgeBaseCategory
                selectedKbItem={selectedKbItem}
                kbItems={kbItems}
                onCreateClick={onCreateClick}
                isLoading={isLoading}
                canEdit={canEdit}
              />
            )}
          </div>
        </div>

        {categoryModalOpen && (
          <CreateEditCategoryModal
            modalMode={modalMode}
            kbItems={kbItems}
            selectedKbEditItem={selectedKbEditItem}
            categoryModalOpen={categoryModalOpen}
            setCategoryModalOpen={setCategoryModalOpen}
            handleCreateSave={onCreateClick}
            handleEditSave={onSaveClick}
          />
        )}
      </div>
    </>
  )
}

export default KnowledgeBase
