import React from 'react'

import { GlobeAltIcon } from '@heroicons/react/24/outline'
import { Tooltip } from '@mui/material/'
import { KnowledgeBaseItemStatus } from 'types/graphql'
import { useMediaQuery } from 'usehooks-ts'

import KBStatusChip from './KBStatusChip'

const KBFolderListItem = ({
  folder,
  selectedKbItem,
  handleSetSelectedKbItem,
  userCanEdit,
}) => {
  const isDraft = folder.status === 'DRAFT'
  const isGlobal = folder.isGlobal
  const selected = selectedKbItem?.id === folder.id
  const isTablet = useMediaQuery('(max-width: 1024px)')
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={() => handleSetSelectedKbItem(folder)}
      onKeyDown={(event) => {
        event.key === 'Enter' && handleSetSelectedKbItem(folder)
      }}
      className={`cursor-pointer !border p-4 py-2 text-left ${
        selected
          ? '!border-indigo-600 bg-indigo-50 hover:bg-indigo-100'
          : '!border-gray-50 !border-b-gray-200 bg-gray-50 last:!border-b-0 hover:bg-gray-100'
      }`}
    >
      <div className="flex items-center justify-between gap-2">
        <p className="mb-2 line-clamp-1 grow pt-2 text-sm text-gray-600">
          {folder.title}
        </p>
        <>
          {userCanEdit && isGlobal && (
            <Tooltip title={'Global Item'}>
              <GlobeAltIcon className={'h-4 w-4 text-blue-400'} />
            </Tooltip>
          )}
        </>
        {userCanEdit && isDraft && !isTablet && (
          <KBStatusChip
            status={'DRAFT' as KnowledgeBaseItemStatus}
            size="small"
          />
        )}
      </div>
    </div>
  )
}

export default KBFolderListItem
