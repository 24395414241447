import type { ChangeEvent, Dispatch, SetStateAction } from 'react'

import { Checkbox, FormControlLabel } from '@mui/material'

import type { HubDashLinkedContent } from '../../HubDashLayoutListCell'

import type {
  LinkedContent,
  LinkedLearnerTaskDetails,
} from './LinkLearningContentDialog'

interface LearnerCourseSelectorProps {
  options: LinkedContent[]
  selectedItems: HubDashLinkedContent[]
  setSelectedItems: Dispatch<SetStateAction<HubDashLinkedContent[]>>
}

const LearnerCourseSelector = ({
  options,
  selectedItems,
  setSelectedItems,
}: LearnerCourseSelectorProps) => {
  const handleTaskToggle = (
    checked: boolean,
    courseId: number,
    task: LinkedLearnerTaskDetails,
  ) => {
    setSelectedItems((prevSelectedItems) => {
      const matchingCourse = prevSelectedItems?.find(
        (selectedCourse) => selectedCourse?.resourceId === courseId,
      )

      if (!matchingCourse) return prevSelectedItems

      const linkedMeta =
        (matchingCourse?.linkMetaData as LinkedLearnerTaskDetails[]) ?? []

      const updatedCourse = {
        ...matchingCourse,
        linkMetaData: checked
          ? [...linkedMeta, task]
          : linkedMeta.filter((lesson) => lesson?.taskId !== task?.taskId),
      }

      return prevSelectedItems.map((course) =>
        course.resourceId === courseId ? updatedCourse : course,
      )
    })
  }

  const checkTaskIsToggled = (
    selectedItems: HubDashLinkedContent[],
    courseId: number,
    task: LinkedLearnerTaskDetails,
  ): boolean => {
    const matchedSelectedCourse = selectedItems?.find(
      (selected) => selected?.resourceId === courseId,
    )

    if (matchedSelectedCourse) {
      const linkedMetaData =
        matchedSelectedCourse?.linkMetaData as unknown as LinkedLearnerTaskDetails[]

      return !!linkedMetaData?.find(
        (selectedTaskOption: LinkedLearnerTaskDetails) =>
          selectedTaskOption?.taskId === task.taskId,
      )
    }

    return false
  }

  return (
    <div className="flex flex-col gap-2">
      {options?.map((courseOption) => {
        const isSelected = !!selectedItems?.find(
          (selected) => selected?.resourceId === courseOption?.resourceId,
        )

        return (
          <div
            key={courseOption?.resourceId}
            className="rounded border bg-gray-100 p-1"
          >
            <FormControlLabel
              className="m-0 w-full rounded border bg-white hover:bg-gray-50"
              control={
                <Checkbox
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    if (event.target.checked) {
                      setSelectedItems((prevSelectedItems) => [
                        ...prevSelectedItems,
                        courseOption,
                      ])
                    } else {
                      setSelectedItems((prevSelectedItems) =>
                        prevSelectedItems?.filter(
                          (item) =>
                            item?.resourceId !== courseOption?.resourceId,
                        ),
                      )
                    }
                  }}
                  checked={isSelected}
                />
              }
              label={courseOption?.resourceName}
            />
            {isSelected && (
              <div className="flex flex-col gap-1 px-4 pb-4">
                <p className="py-2 text-sm italic text-gray-500">
                  Select Learner Tasks to Link
                </p>
                {courseOption?.tasks?.map((task) => {
                  return (
                    <FormControlLabel
                      key={task?.taskId}
                      className="m-0 w-full rounded border bg-white text-sm hover:bg-gray-50"
                      control={
                        <Checkbox
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            handleTaskToggle(
                              event.target.checked,
                              courseOption?.resourceId,
                              task,
                            )
                          }
                          checked={checkTaskIsToggled(
                            selectedItems,
                            courseOption?.resourceId,
                            task,
                          )}
                        />
                      }
                      label={task?.taskName}
                    />
                  )
                })}
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default LearnerCourseSelector
