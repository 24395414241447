import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import { InputAdornment, styled } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { useLocalStorage } from 'usehooks-ts'

import { navigate, routes } from '@redwoodjs/router'

import {
  ReactMapCategory,
  ReactMap,
  ReactMapsEditorRefetch,
} from 'src/components/ReactMap/ReactMapSidePanelCell/ReactMapSidePanelCell'
import { filterReactMaps } from 'src/Util'
import { useAuth } from 'web/src/Providers'

import ProcessMapViewerCell from '../ProcessMapViewerCell'

export interface Option {
  id: number
  name: string
  __typename: string
  type: string
  group: string
}

export interface ReactMapSidePanelProps {
  categoryList: ReactMapCategory[]
  uncategorisedMaps: ReactMap[]
  selectedMap?: number
  setSelectedMap?: Dispatch<SetStateAction<number>>
  refetch?: ReactMapsEditorRefetch
}

const ReactMapMobile: FC<ReactMapSidePanelProps> = ({
  categoryList,
  setSelectedMap,
}) => {
  const [searchVal, setSearchVal] = useState('')
  const [searchValue, setSearchValue] = useState<Option>(null)
  const { currentUser } = useAuth()

  function mapSelected(id: number) {
    setSelectedMap(id)
    navigate(
      routes.reactMapById({
        id: id,
      }),
    )
  }

  // on mobile we return only published maps and categories
  const filteredReactMapCategories = filterReactMaps(categoryList, searchVal)

  // filtering out categories that have no maps
  const reactMapCategories = filteredReactMapCategories.filter(
    (category) => category.reactMaps.length > 0,
  )

  const getOptions = () => {
    return reactMapCategories
      .flatMap((category) => {
        const categoryName = category.name

        // Map the reactMaps within the category to the desired format with type 'Map' and group set to the category name
        const mapOptions = category.reactMaps.map((map) => ({
          id: map.id,
          name: map.name,
          __typename: map.__typename,
          type: 'Map',
          group: categoryName,
        }))

        // Return the mapOptions array directly, effectively flattening the nested structure
        return mapOptions
      })
      .sort((a, b) => a.group.localeCompare(b.group))
  }

  const [localProcessMap, setLocalProcessMap] = useLocalStorage(
    `process-map-${currentUser.parentData.id}-${currentUser.userData.id}`,
    null,
  )

  useEffect(() => {
    if (localProcessMap) {
      // If selectedProcessMap exists, use it
      setSelectedMap(localProcessMap)
      mapSelected(localProcessMap)
    }
  }, [])

  useEffect(() => {
    if (searchValue?.__typename) {
      // Otherwise, use the searchValue
      if (searchValue.__typename === 'ReactMap') {
        setSelectedMap(searchValue.id)
        setLocalProcessMap(searchValue.id)
        mapSelected(searchValue.id)
      } else {
        mapSelected(searchValue.id)
      }
    }
  }, [searchValue, localProcessMap])

  return (
    <div className="flex align-middle flex-1 w-full">
      <div className="flex flex-col flex-1 w-full">
        <header className="p-3 pb-3">
          <div className="flex-1 min-w-0">
            <Autocomplete
              options={getOptions()}
              id="autocomplete-search-mobile"
              data-testid="mapper-mobile-search-bar"
              size="medium"
              fullWidth={true}
              freeSolo
              autoHighlight
              groupBy={(option) => option?.group}
              getOptionLabel={(option: Option) => option?.name}
              renderGroup={(params) => (
                <li {...params} key={params.key}>
                  <div className="sticky top-[-8px] px-[8px] py-[4px] bg-white text-indigo-600">
                    {params.group}
                  </div>
                  <ul className="p-0">{params.children}</ul>
                </li>
              )}
              onChange={(
                _event: React.ChangeEvent<HTMLInputElement>,
                newValue: Option,
              ) => {
                setSearchValue(newValue)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search Maps & Categories"
                  className="bg-white"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchVal(event.target.value)
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <MagnifyingGlassIcon className="w-5 h-5 text-gray-500" />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
        </header>
        {localProcessMap ? (
          <ProcessMapViewerCell id={localProcessMap} isMobile isPlayerView />
        ) : (
          <div className="flex items-center justify-center flex-1">
            Select a map to view
          </div>
        )}
      </div>
    </div>
  )
}

export default ReactMapMobile
