import React, { FC } from 'react'

import {
  ArchiveBoxArrowDownIcon,
  ArrowPathIcon,
  DocumentDuplicateIcon,
  EllipsisHorizontalIcon,
  HomeIcon,
  PencilSquareIcon,
  ShareIcon,
} from '@heroicons/react/24/outline'
import { Breadcrumbs, Link, Menu, MenuItem, Tooltip } from '@mui/material'
import { useMediaQuery } from 'usehooks-ts'

import { navigate, routes } from '@redwoodjs/router'
import { toast } from '@redwoodjs/web/toast'

import { KB_ITEM_TYPE } from '../KnowledgeCell'
import { KBItem, KBItemSorted } from '../KnowledgeCell/types'
import AutoSaveIndicator from '../Library/AutoSaveIndicator/AutoSaveIndicator'
import Button from '../Library/Button/Button'
import IconButton from '../Library/IconButton/IconButton'
import Switch from '../Library/Switch'

import { ModalState } from './Modals/CreateEditCategoryModal'

interface KnowledgeHeaderProps {
  selectedKbItem: KBItemSorted
  setSelectedKbItem: (value: KBItemSorted) => void
  setSelectedKbEditItem: (value: KBItem) => void
  togglePublishedStatus: (selectedKbItem: KBItemSorted) => void
  selectedAncestors: KBItemSorted[]
  isEditing: boolean
  setIsEditing: (value: boolean) => void
  isSaving: boolean
  onArchiveClick: (selectedKbItem: KBItemSorted) => Promise<void>
  onDuplicateClick: (selectedKbItem: KBItemSorted) => Promise<void>
  userCanEdit: boolean
  isPublished: boolean
  saveDisabled?: boolean
  setCategoryModalOpen: (value: boolean) => void
  setModalMode: (value: ModalState) => void
  sidebarOpen: boolean
  isDuplicating: boolean
  isArchiving: boolean
}

const KnowledgeHeader: FC<KnowledgeHeaderProps> = ({
  selectedKbItem,
  setSelectedKbItem,
  setSelectedKbEditItem,
  togglePublishedStatus,
  selectedAncestors,
  isEditing,
  setIsEditing,
  isSaving,
  onArchiveClick,
  onDuplicateClick,
  userCanEdit,
  isPublished,
  saveDisabled,
  setCategoryModalOpen,
  setModalMode,
  sidebarOpen,
  isDuplicating,
  isArchiving,
}) => {
  const [settingsMenuAnchor, setSettingsMenuAnchor] =
    React.useState<null | HTMLElement>(null)

  const settingsMenuOpen = Boolean(settingsMenuAnchor)
  const isCategory = selectedKbItem?.type === KB_ITEM_TYPE.CATEGORY

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setSettingsMenuAnchor(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setSettingsMenuAnchor(null)
  }

  const isMobile = useMediaQuery('(max-width: 640px)')

  // strip out edit link if copied app/knowledge/edit/54 -> app/knowledge/54
  const stripped = window.location.href.replace(
    /(\/app\/knowledge\/)edit\/(\d+)/,
    '$1$2',
  )
  const copyToClipboard = () => {
    navigator.clipboard.writeText(stripped)
    toast.success('Link copied to clipboard')
  }

  const updateUrlPath = () => {
    window.history.pushState({}, '', stripped)
  }

  const handleDuplicateDocument = (selectedKbItem: KBItemSorted) => {
    onDuplicateClick(selectedKbItem).then(() => {
      handleCloseMenu()
    })
  }

  const handleArchiveClick = (selectedKbItem: KBItemSorted) => {
    onArchiveClick(selectedKbItem).then(() => {
      handleCloseMenu()
    })
  }

  return (
    <>
      {!isMobile && selectedKbItem ? (
        <>
          <div className={`${sidebarOpen ? 'px-0' : 'px-4'}`}></div>
          <div className="mx-auto flex h-full w-full items-center justify-between px-4 py-1.5 lg:max-w-[100ch]">
            <div className="flex items-center justify-between">
              <Link
                underline="hover"
                onClick={() => {
                  navigate(routes.knowledge())
                }}
                className="flex items-center p-2 text-xs text-gray-500"
                data-testid="knowledge-base-home-button"
              >
                <HomeIcon className="mr-1 h-4 w-4 text-gray-500 hover:text-indigo-500" />
              </Link>
              <Breadcrumbs
                separator="/"
                aria-label="breadcrumb"
                className="cursor-pointer"
                sx={{
                  '.MuiBreadcrumbs-separator': {
                    padding: '0',
                    margin: '0',
                    opacity: '0.3',
                  },
                }}
              >
                {selectedAncestors?.length >= 1 &&
                  selectedAncestors.map((ancestor) => (
                    <Link
                      underline="hover"
                      onClick={() => {
                        navigate(routes.knowledgeWithId({ id: ancestor?.id }))
                      }}
                      className="p-2 text-xs text-gray-500 hover:text-indigo-500"
                      key={ancestor?.id}
                    >
                      {ancestor?.title}
                    </Link>
                  ))}
              </Breadcrumbs>
            </div>
            {userCanEdit ? (
              <div className="flex items-center gap-2">
                {selectedKbItem.type !== 'CATEGORY' && isEditing && (
                  <AutoSaveIndicator isSaving={isSaving} />
                )}
                <p
                  className={`text-sm font-medium ${isPublished ? 'text-green-600' : 'text-gray-400'} `}
                >
                  {isPublished ? 'Published' : 'Draft'}
                </p>
                <Tooltip
                  title={`${isPublished ? 'Unpublish' : 'Publish'}`}
                  placement="top"
                >
                  <div
                    className="flex items-center justify-center"
                    data-intercom-target="article-publish-button"
                    data-testid="article-publish-button"
                  >
                    <Switch
                      checked={isPublished}
                      onChange={() => togglePublishedStatus(selectedKbItem)}
                      disabled={saveDisabled || isSaving}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </div>
                </Tooltip>

                {isEditing && !isCategory && (
                  <>
                    <Button
                      className="shrink-0"
                      variant="outlined"
                      size="small"
                      fullWidth={false}
                      onClick={() => {
                        setIsEditing(false)
                        updateUrlPath()
                      }}
                      disabled={saveDisabled || isSaving}
                      buttonDataTestId="exit-editing-button"
                    >
                      Exit Editing
                    </Button>
                  </>
                )}
                {!isEditing && !isCategory && (
                  <Button
                    className="shrink-0"
                    variant="contained"
                    size="small"
                    fullWidth={false}
                    onClick={() => {
                      setIsEditing(true)
                      setSelectedKbItem(selectedKbItem)
                      setSelectedKbEditItem(selectedKbItem)
                      navigate(
                        routes.knowledgeEditWithId({
                          id: selectedKbItem.id,
                        }),
                      )
                    }}
                  >
                    Edit Article
                  </Button>
                )}

                <div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleOpenMenu}
                    className="!p-0 hover:!bg-gray-100"
                  >
                    <EllipsisHorizontalIcon width={30} height={30} />
                  </IconButton>
                  <Menu
                    open={settingsMenuOpen}
                    onClose={handleCloseMenu}
                    anchorEl={settingsMenuAnchor}
                  >
                    <MenuItem
                      onClick={() => {
                        setModalMode(ModalState.EDIT)
                        setCategoryModalOpen(true)
                        handleCloseMenu()
                      }}
                      className="text-gray-500"
                    >
                      <PencilSquareIcon
                        className="mr-2 h-5 w-5"
                        data-intercom-target="article-settings-button"
                      />
                      Settings
                    </MenuItem>
                    {selectedKbItem.type === KB_ITEM_TYPE.DOCUMENT && (
                      <MenuItem
                        onClick={() => {
                          handleDuplicateDocument(selectedKbItem)
                        }}
                        className="text-gray-500"
                        data-intercom-target="duplicate-article-button"
                      >
                        {isDuplicating && (
                          <ArrowPathIcon className="mr-2 h-5 w-5 animate-spin" />
                        )}
                        {!isDuplicating && (
                          <DocumentDuplicateIcon className="mr-2 h-5 w-5" />
                        )}
                        Duplicate
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={() => {
                        copyToClipboard()
                        handleCloseMenu()
                      }}
                      className="text-gray-500"
                    >
                      <ShareIcon className="mr-2 h-5 w-5" />
                      Share
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleArchiveClick(selectedKbItem)
                      }}
                      className="text-red-500 hover:!bg-red-50"
                    >
                      {isArchiving && (
                        <ArrowPathIcon className="mr-2 h-5 w-5 animate-spin text-red-500" />
                      )}
                      {!isArchiving && (
                        <ArchiveBoxArrowDownIcon className="mr-2 h-5 w-5 text-red-500" />
                      )}
                      Archive
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            ) : null}
          </div>
          <div className="px-4"></div>
        </>
      ) : (
        <div className="mx-auto h-full min-h-[74px] px-6 py-1.5 2xl:max-w-[100ch]"></div>
      )}
    </>
  )
}

export default KnowledgeHeader
