import { useCallback } from 'react'

import type { OperationVariables } from '@apollo/client'
import type { OutputData } from '@editorjs/editorjs'
import type { TypedDocumentNode } from '@graphql-typed-document-node/core'
import type { DocumentNode } from 'graphql'
import { isNil } from 'ramda'
import type {
  UpdateKnowledgeBaseItemMutation,
  UpdateKnowledgeBaseItemMutationVariables,
  KnowledgeBaseItemStatus,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import type { KBItemSorted } from 'src/components/KnowledgeCell/types'
import { useAuth } from 'src/Providers'

const UpdateMutation = gql`
  mutation UpdateKnowledgeBaseItemMutation(
    $id: Int!
    $input: UpdateKnowledgeBaseItemInput!
  ) {
    updateKnowledgeBaseItem(id: $id, input: $input) {
      id
    }
  }
`

const useUpdateMutation = (params: {
  refetchQuery: DocumentNode | TypedDocumentNode<any, OperationVariables>
  statuses: KnowledgeBaseItemStatus[]
  asAdmin: boolean
}) => {
  const { refetchQuery, statuses, asAdmin } = params
  const { currentUser } = useAuth()
  const [updateMutation] = useMutation<
    UpdateKnowledgeBaseItemMutation,
    UpdateKnowledgeBaseItemMutationVariables
  >(UpdateMutation, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: refetchQuery,
        variables: {
          statuses,
          asAdmin,
          clientId: currentUser.membershipData.clientId,
        },
      },
    ],
  })

  type UpdateProps = {
    kbItemId: number
    title?: string
    url?: string
    text?: string
    document?: OutputData
    status?: KnowledgeBaseItemStatus
    parent?: KBItemSorted
    isGlobal?: boolean
  }
  const update = useCallback(
    async (props: UpdateProps) => {
      const { kbItemId, title, url, text, document, status, parent, isGlobal } =
        props

      const variables: UpdateKnowledgeBaseItemMutationVariables = {
        id: kbItemId,
        input: {
          ...(parent?.id ? { parentId: parent?.id } : { parentId: null }),
          ...(status && { status }),
          ...(!isNil(title) && { title }),
          ...(!isNil(url) && { url }),
          ...(!isNil(text) && { text }),
          ...(!isNil(isGlobal) && { isGlobal }),
          ...(document && { document: document as any }),
        },
      }

      const result = await updateMutation({ variables })

      return result
    },
    [updateMutation],
  )

  return update
}

export default useUpdateMutation
